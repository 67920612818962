import React, {useEffect, useRef, useState} from 'react';
import "./ContextMenu.scss";
import {Portal} from "react-portal";
import classNames from "classnames";


const ContextMenu = (props: {
    onClose?: () => void,
    noScrollTop?: JSX.Element,
    content?: JSX.Element[],
    alignTo?: HTMLElement,
    className?: string,
    size?: "sm" | "md",
    position?: {
        x: number,
        y: number,
    }
}) => {

    const {
        onClose,
        position,
        content,
        noScrollTop = null,
        alignTo,
        size= "md",
        className = "",
    } = props;

    const ref:any = useRef();
    const [_position, setPosition] = useState<any>({});
    useEffect(()=>{
        if(position) setPosition(position);
        else if(alignTo){
            const rect = alignTo.getBoundingClientRect();
            setPosition({
                x: rect.x,
                y: rect.height + rect.y + 4
            });
        }
    }, [position, alignTo]);

    useEffect(()=>{
        if(ref?.current && document.body.clientWidth > 768){
            const item = ref.current;
            const docWidth = document.body.clientWidth;
            const docHeight = document.body.clientHeight;
            const itemPosition = item.getBoundingClientRect();

            if(itemPosition.left + itemPosition.width + 4 > docWidth) item.style.left = docWidth - 4 - itemPosition.width + "px";

                if(itemPosition.top + itemPosition.height + 4 > docHeight) item.style.top = docHeight - 4 - itemPosition.height + "px";
        }
    }, [ref, _position]);

    const classes = classNames("context-menu", className, {[`size-${size}`]: true});

    const contextContent = <div className={"context-menu-cover"} onClick={(e) => {
        // @ts-ignore
        if (!e.target.closest(".context-menu")) onClose();
    }}>

        <div ref={ref} className={classes} style={{left: _position?.x, top: _position?.y}}>
            {noScrollTop && <div key={"no-scroll-top"} className={"no-scroll-content"}>{noScrollTop}</div> || null }
            {content?.length && <div key={"context-content"} className={"context-content"}>{content.map((item, i) => {
                return <div key={"select-item-" + i}>{item}</div>;
            })}
            </div>}
        </div>
    </div>;

    return (<Portal node={document && document.getElementById('context-content')}>
        {contextContent}
    </Portal>);
};

export default ContextMenu;

import React, {useEffect, useMemo, useRef, useState} from 'react';
import Preloader from "../../components/Preloader/Preloader";
import Text from "../../components/Text/Text";
import Space from "../../components/Space/Space";
import Layout from "../../components/Layout/Layout";
import {getImage, sortBy} from "../../helpers";
import Uploader from "../../components/Uploader/Uploader";
import {conn, uploadFile} from "../../helpers/request/connect";
import Editor from "../../components/Editor/Editor";
import Button from "../../components/Button/Button";
import Dialog from "../../components/Dialog/Dialog";
import LangTabs from "../../components/LangTabs/LangTabs";
import Input from "../../components/Input/Input";
import Div from "../../components/Div/Div";
import ListItem from "../../components/ListItem/ListItem";
import {Select} from "../../components/Select/Select";
import {MdAttachFile, MdDelete} from "react-icons/md";
import {FaFileAlt} from "react-icons/fa";
import {Link} from "react-router-dom";

const StatPage = () => {

    const [loading, setLoading] = useState(true);
    const [items, setItems] = useState<any>({});
    const [nDocument, setNDocument] = useState(false);
    const [documentRemove, setDocumentRemove] = useState(false);
    const [documentInfo, setDocumentInfo] = useState<any>({});
    const [files, setFiles] = useState([]);


    useEffect(() => {
        conn("main/getStat").then((res: any) => {
            setItems(res.items);
            setLoading(false);
        });

    }, []);


    return (
        <Layout>
            <Preloader active={loading}/>
            <Text size={"md"} weight={"bold"}>Մենյու</Text>
            <Space/>
            <Div column gap={12}>
                <Link to={"/monuments"}><Text>Հուշարձաններ ({items.monuments})</Text></Link>
                <Link to={"/monument-documents"}><Text>Վավերագրեր ({items.documents})</Text></Link>
                <Link to={"/monument-form-options/regions"}><Text>Բնակավայրեր</Text></Link>
                <Link to={"/programs"}><Text>Ծրագրեր ({items.programs})</Text></Link>
                <Link to={"/media"}><Text>Մեդիա ({items.media})</Text></Link>
                <Link to={"/contacts"}><Text>Հետադարձ կապ ({items.contacts})</Text></Link>
                <Link to={"/donates"}><Text>Նվիրատվություն ({items.donates})</Text></Link>
                <Link to={"/literature"}><Text>Գրականություն ({items.literatures})</Text></Link>
            </Div>

        </Layout>
    );
};

export default StatPage;


import React, {useEffect, useMemo, useState} from 'react';
import Preloader from "../../components/Preloader/Preloader";
import Text from "../../components/Text/Text";
import Space from "../../components/Space/Space";
import Layout from "../../components/Layout/Layout";
import {getImage, sortBy} from "../../helpers";
import Uploader from "../../components/Uploader/Uploader";
import {conn, uploadFile} from "../../helpers/request/connect";
import Editor from "../../components/Editor/Editor";
import Button from "../../components/Button/Button";
import Dialog from "../../components/Dialog/Dialog";
import LangTabs from "../../components/LangTabs/LangTabs";
import Input from "../../components/Input/Input";
import Div from "../../components/Div/Div";
import ListItem from "../../components/ListItem/ListItem";
import {Select} from "../../components/Select/Select";
import {Link} from "react-router-dom";

const DocumentsFormOptions = () => {



    return (
        <Layout>
            <Text size={"md"} weight={"bold"}>Ֆորմաների կարգավորումներ</Text>
            <Space/>
            <Div column>
                <Link to={"/monument-form-options/regions"} key={"doc-opt-reg"}><ListItem hover={true}  title={"Բնակավայրեր"}/></Link>
                <Link to={"/monument-form-options/types"} key={"doc-opt-types"}><ListItem hover={true}  title={"Հուշարձանների տիպեր"}/></Link>
                <Link to={"/monument-form-options/monument-form-sections"} key={"doc-opt-monument-form-sections"}><ListItem hover={true}  title={"Հուշարձանների հատկությունների բաժիններ"}/></Link>
                <Link to={"/monument-form-options/monument-form-items"} key={"doc-opt-monument-form-items"}><ListItem hover={true}  title={"Հուշարձանների հատկություններ"}/></Link>
            </Div>
        </Layout>
    );
};

export default DocumentsFormOptions;


import React, {useState} from 'react';
import Text from "../../components/Text/Text";
import Space from "../../components/Space/Space";
import Layout from "../../components/Layout/Layout";
import Div from "../../components/Div/Div";
import ListItem from "../../components/ListItem/ListItem";
import {Link} from "react-router-dom";
import Button from "../../components/Button/Button";
import Dialog from "../../components/Dialog/Dialog";
import Input from "../../components/Input/Input";
import button from "../../components/Button/Button";
import {conn} from "../../helpers/request/connect";
import {cookies} from "../../helpers";

const SettingsPage = () => {

    const [edit, setEdit] = useState(null);
    const [info, setInfo] = useState<any>({});
    return (
        <Layout>
            <Text size={"md"} weight={"bold"}>Կարգավորումներ</Text>
            <Space size={"sm"}/>
            <Space/>
            <Div column>
                <Text weight={"bold"} size={"xmd"}>Գաղտնաբառ</Text>
                <Space size={"xsm"}/>
                <Button title={"Փոխել գաղտնաբառը"} stretch={"fixed"} onClick={() => setEdit("password")}/>
            </Div>
            {edit === "password" && <Dialog
                title={"Գաղտնաբառ"}
                size={"xsm"}
                onClose={() => {
                    setEdit(null);
                    setInfo({});
                }}
                buttons={{
                    right: [
                        <Div gap={12}>
                            <Button key={"cancel"} title={"Չեղարկել"} color={"transparent"} onClick={()=>{
                                setInfo({});
                                setEdit(null);
                            }}/>
                            <Button key={"save"} title={"Պահպանել"} stretch={"fixed"} color={"green"} onClick={()=>{
                                if(!info.curPass || info.curPass?.length < 5){
                                    alert("Ընթացիկ գաղտնաբառը սխալ է");
                                }else if(!info.newPass || info.newPass?.length < 5){
                                    alert("Գաղտնաբառը պետք է կազմված լինի առնվազն 5 սիմվոլից");
                                }else if(info.newPass !== info.newPass2){
                                    alert("Գաղտնաբառերը չեն համապատասխանում");
                                }else{
                                   conn("settings/password", info).then((res:any)=>{
                                       if(res.error) alert("Ընթացիկ գաղտնաբառը սխալ է");
                                       else if(res.response && res.id && res.token){
                                           cookies.set("ad_id", res.id);
                                           cookies.set("ad_token", res.token);
                                           window.location.reload();
                                       }else{
                                           alert("Error: Unknown error");
                                       }
                                   })
                                }
                            }}/>
                        </Div>
                    ]
                }}
            >
                <Input type={"password"} label={"Ընթացիկ գաղտնաբառ"} placeholder={"Ընթացիկ գաղտնաբառ"} value={info.curPass} onChange={(e) => setInfo(prev => {
                    return {...prev, curPass: e}
                })}/>
                <Space/>
                <Input label={"Նոր գաղտնաբառ"} type={"password"} placeholder={"Նոր գաղտնաբառ"} value={info.newPass} onChange={(e) => setInfo(prev => {
                    return {...prev, newPass: e}
                })}/>
                <Space/>
                <Input label={"Կրկնեք նոր գաղտնաբառը"} type={"password"} placeholder={"Կրկնեք նոր գաղտնաբառը"} value={info.newPass2} onChange={(e) => setInfo(prev => {
                    return {...prev, newPass2: e}
                })}/>

            </Dialog>}
        </Layout>
    );
};

export default SettingsPage;


import "./ScrollBar.scss";
import {useLocation, useNavigate, useParams} from "react-router";
import {useEffect, useMemo, useRef, useState} from "react";
import React from 'react';



const ScrollBar = (props: {children?: JSX.Element | JSX.Element[], getRef?: (e)=>void, id?: string, scrollTopOnchangeParams?: boolean}) => {
    const {children, getRef, id, scrollTopOnchangeParams} = props;
    const params = useParams();
    const scrollRef = useRef();


    useEffect(()=>{
        if(scrollTopOnchangeParams){
            // @ts-ignore
            scrollRef?.current.scrollTo({ top: 0, behavior: 'instant' });
        }
    }, [params, scrollTopOnchangeParams])



    useEffect(()=>{
    if(getRef) getRef(scrollRef);
    }, [scrollRef])

        return (
        <div id={id} className={"scrollbar-component" } ref={scrollRef}>
            {children && children || null}
        </div>
    );
};

export default ScrollBar;



import * as React from 'react';
import classNames from "classnames";
import "./Button.scss";
import {useCallback, useEffect, useRef} from "react";
const Button = (props: {
    title?: string | null | JSX.Element,
    description?: string,
    color?: "transparent" | "main" | "red" | "green" | "grey",
    size?: "xsm" | "sm" | "md",
    onClick?: (e: any) => void,
    onMouseDown?: (e: any) => void,
    iconLeft?: JSX.Element,
    iconRight?: JSX.Element,
    className?: string,
    disabled?: boolean,
    hasHover?: boolean,
    stretch?: "full" | "fixed",
    loading?: boolean,
    clickOnEnter?: boolean
}) => {
    const {
        title,
        description,
        color = "main",
        size = "sm",
        onClick,
        onMouseDown,
        iconLeft,
        iconRight,
        className = "",
        disabled,
        stretch,
        hasHover,
        loading,
        clickOnEnter
    } = props;
    const classes = classNames("button", className, {
        [`size-${size}`]: true,
        [`color-${color}`]: true,
        "hover-button": onClick || onMouseDown,
        "p-left": !iconLeft && title,
        "p-right": !iconRight && title,
        "disabled": disabled,
        "stretch": stretch === "full",
        "fixed-w": stretch === "fixed",
        "hover": hasHover,
        loading: loading,
    });


    const buttonRef: any = useRef();

    const clickOnButton = useCallback((e: any)=>{
        if(e.key === "Enter" && !e.shiftKey){
            e.preventDefault();
            buttonRef?.current.click();
        }
    }, [buttonRef]);

    useEffect(()=>{
        if(clickOnEnter){
            document.addEventListener("keyup", clickOnButton);
        }

        return ()=> document.removeEventListener("keyup", clickOnButton);


    }, [buttonRef, clickOnEnter]);


    return (
        <div  ref={buttonRef} className={classes} onClick={onClick && !disabled? onClick : undefined} onMouseDown={onMouseDown && !disabled? onMouseDown : undefined}>
            {iconLeft ? <div className={"right-icon button-icon"}>{iconLeft}</div> : null}
            {title ? <div className={"button-title-content"}>
                <div className={"button-title"}>{title}</div>
                {description ? <div className={"button-title-description"}>{description}</div>: null}
            </div> :  null}
            {iconRight ? <div className={"left-icon button-icon"}>{iconRight}</div> : null}
        </div>
    );
};

export default Button;

import {Cookies} from "react-cookie";
import {base_url, uploads_url} from "./request/connect";

export const isValidEmail = (email:string)=> {
    return /\S+@\S+\.\S+/.test(email);
}

export const checkAuth = ()=>{
    const isAuth = cookies.get("ad_token") && cookies.get("ad_id");
    if(isAuth){
        cookies.set("ad_id", cookies.get("ad_id"), 30);
        cookies.set("ad_token", cookies.get("ad_token"), 30);
        return isAuth;
    }
    return cookies.get("ad_token") && cookies.get("ad_id");
}
export const getImage = (img?: string, type= "images")=>{
    if(type === "youtube") return "https://i.ytimg.com/vi_webp/"+ img +"/sddefault.webp";
   else return  uploads_url + "/"+ type +"/" +(img? img : "no-image.jpg");
}

export const getZero = (x: number)=> {
    return Number(x) < 10? "0" + x: x;
}

export const getDate = (date: number, show_time?: boolean)=>{
    const d = new Date(date * 1000);
    return getZero(d.getDate()) + "/" + getZero(d.getMonth() + 1) + "/" + d.getFullYear() + (show_time? " " + getZero(d.getHours()) + ":" + getZero(d.getMinutes()): "");
}

export const getDateInterval = (date:number)=> {
    const now = unix(new Date().getTime());
    const difference = now - Number(date);

    return difference;
}

export const unix = (t: number)=> Math.floor(Number(t) / 1000);






const getExpires = (days: number)=>{
    const expires = new Date();
    expires.setTime(expires.getTime() + 3600 * 1000 * 24 * days);
    return expires;
}
const cooks = new Cookies();
export const cookies = {
    set: async (key: string, value: string, days?: number)=>{
        const options:any = {};
        if(days) options.expires = getExpires(days);
        cooks.set(key, value, options);
    },
    get: (key: string)=>{
        return cooks.get(key);
    }
}

export const scrollToLastMessage = (id, smooth = true, div?: string)=>{
    setTimeout(()=>{
        const block = (div? document.querySelector(div) : document)?.querySelector("*[data-id='"+id+"']");
        if(block) block.scrollIntoView(false);
    }, 1000);
}

export const rates = [
    {title: "AMD", id: "amd", symbol: "֏"},
    {title: "USD", id: "usd", symbol: "$"},
    {title: "RUB", id: "rub", symbol: "₽"},
]

export const getCategoriesID = (id, categories)=>{
    const cats = [];
    if(categories?.length) categories.forEach(cat=>{
          if(Number(id) === Number(cat.id)) cats.push(cat);
          else if(cat.items){
              cat.items?.forEach(cat2=>{
                  if(Number(id) === Number(cat2.id)) {
                      cats.push(cat);
                      cats.push(cat2);
                  }
                  else if(cat2.items){
                      cat2.items?.forEach(cat3=>{
                          if(Number(id) === Number(cat3.id)) {
                              cats.push(cat);
                              cats.push(cat2);
                              cats.push(cat3);
                          }
                      });
                  }
              })
          }
    });

    return cats;
}


export const isValidUrl = urlString=> {
    const urlPattern = new RegExp('^(https?:\\/\\/)?'+ // validate protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // validate domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))'+ // validate OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // validate port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?'+ // validate query string
        '(\\#[-a-z\\d_]*)?$','i'); // validate fragment locator
    return !!urlPattern.test(urlString);
}

export const sortBy = (arr, by)=>{
    return arr.sort((a:any, b:any)=>{
        return Number(a[by] - b[by]);
    })
}

import React, {useEffect, useMemo, useState} from 'react';
import Preloader from "../../components/Preloader/Preloader";
import Text from "../../components/Text/Text";
import Space from "../../components/Space/Space";
import Layout from "../../components/Layout/Layout";
import {getImage, sortBy} from "../../helpers";
import Uploader from "../../components/Uploader/Uploader";
import {conn, uploadFile} from "../../helpers/request/connect";
import Editor from "../../components/Editor/Editor";
import Button from "../../components/Button/Button";
import Dialog from "../../components/Dialog/Dialog";
import LangTabs from "../../components/LangTabs/LangTabs";
import Input from "../../components/Input/Input";
import Div from "../../components/Div/Div";
import ListItem from "../../components/ListItem/ListItem";
import {Select} from "../../components/Select/Select";

const docExcludeTypes = [1, 2, 3, 4, 64, 65, 66, 67, 68, 69, 70]

const DocumentsFormItems = () => {

    const [loading, setLoading] = useState(true);
    const [items, setItems] = useState([]);
    const [section, setSection] = useState([]);
    const [nDocument, setNDocument] = useState(false);
    const [documentRemove, setDocumentRemove] = useState(false);
    const [documentInfo, setDocumentInfo] = useState<any>({});



    useEffect(() => {
        conn("document-form-items/get").then((res: any) => {
            if (res.items) setItems(res.items);
            conn("document-form-items/getSections").then((res: any) => {
                if (res.items) setSection(res.items);
                setLoading(false);
            });
        });
    }, [])


    const saveDocument = (data) => {
        conn("document-form-items/saveItem", data).then((res) => {
             window.location.reload();
        });
    }





    return (
        <Layout>
            <Preloader active={loading}/>
            <Text size={"md"} weight={"bold"}>Document form items</Text>
            <Space size={"sm"}/>
            <Button color={"green"} title={"Ավելացնել նորը"} stretch={"fixed"} onClick={() => setNDocument(true)}/>
            <Space/>
            <Div column>
                {section?.map(sec=>{
                    return <div key={"section-" + sec.id}>
                        <Space/>
                        <Text weight={"bold"} size={"xmd"}>{sec.title_hy}</Text>
                        {items?.filter(x => x.section === sec.id).map((val) => {
                            return <ListItem hover={true} key={val.id} title={val.sort + " " + val.title_hy} onClick={() => {
                                setDocumentInfo(val);
                                setNDocument(true);
                            }}/>
                        })}
                        <Space size={"xsm"}/>
                        <Button color={"green"} title={"Ավելացնել նորը"} size={"xsm"} onClick={()=>{
                            setDocumentInfo({section: sec.id});
                            setNDocument(true);
                        }}/>
                        <Space line={true}/>
                    </div>
                })}

            </Div>


            {nDocument && <Dialog buttons={{
                right: <Div gap={12}>
                    <Button title={"Պահպանել"} color={"green"} stretch={"fixed"} onClick={() => {

                        if(!documentInfo.title_en?.length || !documentInfo.title_hy?.length) alert("Լրացրեք պարտադիր դաշտերը");
                        else {
                            setLoading(true);
                            saveDocument({...documentInfo, section: documentInfo.section ?? section?.[0].id});
                            setDocumentInfo({});
                        }
                    }}/>
                    {documentInfo.id && docExcludeTypes.indexOf(Number(documentInfo.id)) === -1 ?
                        <Button title={"Հեռացնել"} onClick={() => setDocumentRemove(true)} color={"red"}/>: null}
                </Div>
            }} size={"sm"} title={"Document form item"} onClose={() => {
                setNDocument(false);
                setDocumentInfo({});
            }}>

                <Space size={"sm"}/>
                <LangTabs tabs={[

                    {
                        title: "Հայերեն",
                        content: <><Input placeholder={"Վերնագիր"} value={documentInfo.title_hy}
                                          onChange={(e) => setDocumentInfo(prev => {
                                              return {...prev, title_hy: e}
                                          })}/>
                        </>
                    },
                    {
                        title: "English",
                        content: <><Input placeholder={"Title"} value={documentInfo.title_en}
                                          onChange={(e) => setDocumentInfo(prev => {
                                              return {...prev, title_en: e}
                                          })}/>
                        </>
                    },
                ]}/>
                <Space size={"sm"}/>
                <Text size={"xmd"} color={"grey-middle"}>Form Section</Text>
                <Space size={"xsm"}/>
                <Select
                    onChange={(e)=>setDocumentInfo(prev=>{return {...prev, section: e.id}})}
                    selected={documentInfo.section ?? section?.[0].id}
                    options={section?.map(v=>{return{title: v.title_hy, id: v.id}})}/>
                {!documentInfo.id || docExcludeTypes.indexOf(Number(documentInfo.id)) === -1 ? <>
                    <Space size={"sm"}/>
                <Text size={"xmd"} color={"grey-middle"}>Input type</Text>
                <Space size={"xsm"}/>
                <Select
                    onChange={(e) => setDocumentInfo(prev => {
                        return {...prev, input_type: e.id}
                    })}
                    selected={documentInfo.input_type ?? "input"}
                    options={[
                        {title: "Input", id: "input"},
                        {title: "TextArea", id: "textarea"},
                        /* {title: "Region", id: "region"},
                         {title: "Location", id: "location"},
                         {title: "Monument types", id: "types"},
                         {title: "Metrics", id: "metrics"},*/
                    ]}/></>: null}
                <Space size={"sm"}/>
                <Text size={"xmd"} weight={"bold"}>Order</Text>
                <Input placeholder={"Order"} value={documentInfo.sort ?? "0"} onChange={(e)=>setDocumentInfo(prev=>{return{...prev, sort: e}})}/>
            </Dialog>}
            {documentRemove && <Dialog
                buttons={{
                    right: <Div gap={12}>
                        <Button title={"Չեղարկել"} color={"transparent"} onClick={() => setDocumentRemove(false)}/>
                        <Button title={"Հեռացնել"} color={"red"} onClick={() => {
                            conn("document-form-items/remove", {id: documentInfo.id}).then(() => window.location.reload())
                        }}/>
                    </Div>
                }}
                size={"xsm"} title={"Հեռացնել"} onClose={() => setDocumentRemove(false)}>
                <Text align={"center"}>Դուք ի՞րոք ցանկանում եք հեռացնել <Text
                    weight={"bold"}>{documentInfo.title_en}?</Text></Text>
            </Dialog>}
        </Layout>
    );
};

export default DocumentsFormItems;


import React, {useEffect, useMemo, useState} from 'react';
import Preloader from "../../components/Preloader/Preloader";
import Text from "../../components/Text/Text";
import Space from "../../components/Space/Space";
import Layout from "../../components/Layout/Layout";
import {getImage, getZero, sortBy} from "../../helpers";
import Uploader from "../../components/Uploader/Uploader";
import {conn, uploadFile} from "../../helpers/request/connect";
import Editor from "../../components/Editor/Editor";
import Button from "../../components/Button/Button";
import Dialog from "../../components/Dialog/Dialog";
import LangTabs from "../../components/LangTabs/LangTabs";
import Input from "../../components/Input/Input";
import Div from "../../components/Div/Div";
import ListItem from "../../components/ListItem/ListItem";
import {Select} from "../../components/Select/Select";
import ListPicker from "../../components/ListPicker/ListPicker";

const img_types = [
    {id: "metrics", title: "Չափագրություններ"},
    {id: "plans", title: "Հատակագծեր"},
    {id: "sketches", title: "Գրչանկարներ"},
    {id: "photos", title: "Լուսանկարներ"},
    {id: "reorganizations", title: "Վերակազմություններ"}
];
const video_types = ["films", "filming", "programs"];


const DocumentsEdit = () => {

    const [loading, setLoading] = useState(true);
    const [info, setInfo] = useState<any>({bold: false});
    const [items, setItems] = useState([]);
    const [regions, setRegions] = useState([]);
    const [nDocument, setNDocument] = useState(false);
    const [documentRemove, setDocumentRemove] = useState(false);
    const [documentInfo, setDocumentInfo] = useState<any>({});
    const [types, setTypes] = useState([]);
    const [forms, setForms] = useState([]);
    const [media,setMedia] = useState([]);
    const [mediaValues, setMediaValues] = useState<any>();

    useEffect(() => {
        conn("documents/get").then((res: any) => {
            if (res.items) setItems(res.items);
            if (res.response) {
                setInfo({image: res.response.image, text: res.response.title_en});
            }
            setLoading(false);
        });
        conn("forms/get").then((res: any) => {
            if (res.items) setForms(res.items);
            setLoading(false);
        });
        conn("regions/get").then((res: any) => {
            if (res.items.length) setRegions(sortBy(res.items, "sort"));
            setLoading(false);
        });

        conn("types/get").then((res: any) => {
            if (res.items.length) setTypes(sortBy(res.items, "sort"));
        });

        conn("media/getMedia").then((res: any) => {
            if (res.items) setMedia(res.items);
        });

    }, [])

    useEffect(()=>{
        if(!documentInfo.date || documentInfo.date === "0000-00-00"){
            const now = new Date();
            const today = now.getFullYear() + "-" +  getZero(now.getMonth() + 1) + "-" + getZero(now.getDate());
            setDocumentInfo(prev=>{return{
                ...prev,
                date: today
            }})
        }
    }, [documentInfo])


    const saveDocument = (data) => {
        conn("documents/saveItem", data).then((res) => {
             window.location.reload();
        });
    }

    useEffect(()=>{
            setMediaValues(JSON.parse(documentInfo?.media ?? "{}"));
    }, [media, documentInfo])


    return (
        <Layout>
            <Preloader active={loading}/>
            <Text size={"md"} weight={"bold"}>Հուշարձաններ</Text>
            <Space size={"sm"}/>
            <Text size={"xmd"} color={"grey-middle"}>Նկար</Text>
            <Uploader
                value={info.image ? (typeof info.image === "string" ? getImage(info.image, "cover") : URL.createObjectURL(info.image)) : undefined}
                disabled={loading} type={"image"} size={"l"} onChange={(e) => {
                if (e) {
                    setLoading(true);
                    uploadFile(e, "cover").then((res: any) => {
                        conn("documents/save", {image: res.response}).then(() => window.location.reload());
                    });
                }
            }}/>
            <Space size={"sm"}/>
            <Button color={"green"} title={"Ավելացնել նոր հուշարձան"} onClick={() => setNDocument(true)}/>
            <Space/>
            <Div column>
                {items.map((val) => {
                    return <ListItem hover={true} key={val.id} title={val.sort + " " + val.title_hy} onClick={() => {
                        setDocumentInfo(val);
                        setNDocument(true);
                    }}/>
                })}
            </Div>


            {nDocument && <Dialog buttons={{
                right: <Div gap={12}>
                    <Button title={"Պահպանել"} color={"green"} stretch={"fixed"} onClick={() => {

                        if(!documentInfo.image) alert("Select image");
                        else if(!documentInfo.region || !documentInfo.region2) alert("Select region and country");
                        else if(!documentInfo.type ) alert("Select type of monument");
                        else if(!documentInfo.title_en?.length || !documentInfo.title_hy?.length) alert("Լրացրեք պարտադիր դաշտերը");
                        else {
                            setLoading(true);
                            if (documentInfo.image && typeof documentInfo.image !== "string") {
                                uploadFile(documentInfo.image, "cover", true).then((res: any) => {
                                    saveDocument({...documentInfo, image: res.response, media: JSON.stringify(mediaValues ?? {})});
                                })
                            } else {
                                saveDocument({...documentInfo, media: JSON.stringify(mediaValues ?? {})})
                            }
                            setDocumentInfo({});
                        }
                    }}/>
                    {documentInfo.id &&
                        <Button title={"Հեռացնել"} onClick={() => setDocumentRemove(true)} color={"red"}/>}
                </Div>
            }} size={"full"} title={"Հուշարձան"} onClose={() => {
                setNDocument(false);
                setDocumentInfo({});
            }}>
                <Uploader size={"l"}
                          value={documentInfo.image ? (typeof documentInfo.image === "string" ? getImage(documentInfo.image, "cover") : URL.createObjectURL(documentInfo.image)) : undefined}
                          onChange={(e) => setDocumentInfo(prev => {
                              return {...prev, image: e}
                          })}/>
                <Space size={"sm"}/>
                <Text size={"xmd"} weight={"bold"}>Կոորդինատներ</Text>
                <Space size={"xsm"}/>
                <Input placeholder={"Կոորդինատներ"} value={documentInfo.coordinates}
                       onChange={(e) => setDocumentInfo(prev => {
                           return {...prev, coordinates: e}
                       })}/>
                <Space size={"sm"}/>
                <Text size={"xmd"} weight={"bold"}>Ամսաթիվ</Text>
                <Space size={"xsm"}/>
                <Input type={"date"} value={documentInfo.date}
                       onChange={(e) => setDocumentInfo(prev => {
                           return {...prev, date: e}
                       })}/>
                <Space/>
                <LangTabs tabs={[

                    {
                        title: "Հայերեն",
                        content: <>
                            <Text size={"xmd"} weight={"bold"}>Նկարի տակագիր</Text>
                            <Space size={"xsm"}/>
                            <Input stretch={"full"} placeholder={"Տակագիր"} value={documentInfo.caption_hy}
                                          onChange={(e) => setDocumentInfo(prev => {
                                              return {...prev, caption_hy: e}
                                          })}/>
                        </>
                    },
                    {
                        title: "English",
                        content: <>
                            <Text size={"xmd"} weight={"bold"}>Image caption</Text>
                            <Space size={"xsm"}/>
                            <Input stretch={"full"} placeholder={"Caption"} value={documentInfo.caption_en}
                                          onChange={(e) => setDocumentInfo(prev => {
                                              return {...prev, caption_en: e}
                                          })}/>
                        </>
                    }
                ]}/>
                <Space/>
                <Text size={"xmd"} weight={"bold"}>Շրջան</Text>
                <Space/>
                <Select onChange={(e)=>{
                    setDocumentInfo(prev=>{return{...prev, region: e.id, region2: null}});
                }} selected={documentInfo?.region} options={regions?.filter(x => !x.parent_id || x.parent_id === "0")?.map(v=>{return {title: v.title_hy, id: v.id}})}/>
                <Space size={"sm"}/>
                <Text size={"xmd"} weight={"bold"}>Բնակավայր</Text>
                <Space/>
                <Select onChange={(e)=>{
                    setDocumentInfo(prev=>{return{...prev, region2: e.id}});
                }} options={regions?.filter(x => x.parent_id === documentInfo.region)?.map(v=>{return {title: v.title_hy, id: v.id}})} selected={documentInfo.region2}/>
                <Space size={"sm"}/><Text size={"xmd"} weight={"bold"}>Հուշարձանի տիպը</Text>
                <Space/>
                <Select onChange={(e)=>{
                    setDocumentInfo(prev=>{return{...prev, type: e.id}});
                }} options={types?.map(v=>{return {title: v.title_hy, id: v.id}})} selected={documentInfo.type}/>
                <Space size={"sm"}/>
                <LangTabs tabs={[

                    {
                        title: "Հայերեն",
                        content: <><Input placeholder={"Վերնագիր"} value={documentInfo.title_hy}
                                          onChange={(e) => setDocumentInfo(prev => {
                                              return {...prev, title_hy: e}
                                          })}/>
                            <Space size={"sm"}/>
                            <Editor key={"text-hy"} value={documentInfo.text_hy}
                                    onChange={(e) => setDocumentInfo(prev => {
                                        return {...prev, text_hy: e}
                                    })}/>
                            <Space size={"sm"}/>
                            <Text size={"xmd"} color={"grey-middle"}>Վավերագրեր</Text>
                            <Space size={"xsm"}/>
                        <ListPicker value={documentInfo.forms} options={{viewImage:false}} lang={"hy"} items={forms} onChange={(e) => setDocumentInfo(prev => {
                            return {...prev, forms: e}
                        })}/>
                        </>
                    },
                    {
                        title: "English",
                        content: <><Input placeholder={"Title"} value={documentInfo.title_en}
                                          onChange={(e) => setDocumentInfo(prev => {
                                              return {...prev, title_en: e}
                                          })}/>
                            <Space size={"sm"}/>
                            <Editor key={"text-en"} value={documentInfo.text_en}
                                    onChange={(e) => setDocumentInfo(prev => {
                                        return {...prev, text_en: e}
                                    })}/>
                            <Space size={"sm"}/>
                            <Text size={"xmd"} color={"grey-middle"}>Documents</Text>
                            <Space size={"xsm"}/>
                            <ListPicker options={{viewImage:false}} value={documentInfo.forms} lang={"en"} items={forms} onChange={(e) => setDocumentInfo(prev => {
                                return {...prev, forms: e}
                            })}/>
                        </>
                    }
                ]}/>


                <>{img_types.map(type=>{
                    return <>
                        <Space/>
                        <Text size={"xmd"} color={"grey-middle"}>{type.title}</Text>
                        <Space size={"xsm"}/>
                        <ListPicker options={{viewImage: true}} lang={"hy"} items={media.filter(x => x.type === type.id)?.map(x => {return {...x, image: getImage(x.image, "media")}})} onChange={(e)=>{
                            setMediaValues((prev:any)=>{
                                return {
                                    ...prev,
                                    [`${type.id}`]: e
                                }
                            });
                        }}
                                    value={mediaValues?.[type.id]}
                        />
                    </>
                })}</>

                <Space/>
                <Text size={"xmd"} color={"grey-middle"}>Տեսահոլովակներ</Text>
                <Space size={"xsm"}/>
                <ListPicker options={{viewImage: true}} lang={"hy"} items={media.filter(x => video_types.indexOf(x.type) > -1)?.map(x => {return {...x, image: "https://i.ytimg.com/vi_webp/"+ x.image +"/sddefault.webp"}})} onChange={(e)=>setMediaValues(prev=>{return{...prev, video: e}})} value={mediaValues.video}/>

            </Dialog>}
            {documentRemove && <Dialog
                buttons={{
                    right: <Div gap={12}>
                        <Button title={"Չեղարկել"} color={"transparent"} onClick={() => setDocumentRemove(false)}/>
                        <Button title={"Հեռացնել"} color={"red"} onClick={() => {
                            conn("documents/remove", {id: documentInfo.id}).then(() => window.location.reload())
                        }}/>
                    </Div>
                }}
                size={"xsm"} title={"Հեռացնել"} onClose={() => setDocumentRemove(false)}>
                <Text align={"center"}>Դուք ի՞րոք ցանկանում եք հեռացնել <Text
                    weight={"bold"}>{documentInfo.title_en}?</Text></Text>
            </Dialog>}
        </Layout>
    );
};

export default DocumentsEdit;


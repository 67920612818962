import React from 'react';
import "./ListItem.scss";
import classNames from "classnames";


const ListItem = (props: {
    title: string | JSX.Element,
    leftIcon?: JSX.Element,
    rightIcon?: JSX.Element,
    hover?: boolean,
    selected?: boolean,
    hovered?: boolean,
    size?: "md" | "sm" | "lg",
    onClick?: (e:any)=>void,
    onContext?: (e:any)=>void,
    className?: string,
}) => {

    const {
        title,
        leftIcon,
        rightIcon,
        hovered,
        selected,
        hover,
        size = "md",
        onClick,
        onContext,
        className = "",
    } = props;

    const classes = classNames("list-item", className, {
        hover: hover,
        hovered: hovered,
        selected: selected,
        [`size-${size}`]: true,
    });

    return (
        <div
            className={classes}
            onClick={(e)=>{
            if(onClick) onClick(e);
        }}
            onContextMenu={(e)=>{
                if(onContext) {
                    e.preventDefault();
                    onContext(e);
                }
            }}
        >
            {leftIcon && <div className="list-item-icon list-item-icon-left">{leftIcon}</div> || null}
            <div className="list-item-title">{title}</div>
            {rightIcon && <div className="list-item-icon list-item-icon-right">{rightIcon}</div> || null}
        </div>
    );
};

export default ListItem;

export const getCursorPosition = (parent)=> {
    let selection = document.getSelection()
    let range = new Range
    range.setStart(parent, 0)
    range.setEnd(selection.anchorNode, selection.anchorOffset)
    return range.toString().length
}

export const setCursorPosition = (parent, position)=> {
    let child = parent.firstChild
    while(position > 0) {
        let length = child.textContent.length
        if(position > length) {
            position -= length
            child = child.nextSibling
        }
        else {
            if(child.nodeType == 3) return document.getSelection().collapse(child, position)
            child = child.firstChild
        }
    }
}

export const getEditorState = ()=>{
    return {
        bold: document.queryCommandState('bold'),
        italic: document.queryCommandState('italic'),
        underline: document.queryCommandState('underline'),
        justifyLeft: document.queryCommandState('justifyLeft'),
        justifyRight: document.queryCommandState('justifyRight'),
        justifyCenter: document.queryCommandState('justifyCenter'),
        justifyFull: document.queryCommandState('justifyFull'),
        fontColor: document.queryCommandValue("ForeColor"),
        fontSize: document.queryCommandValue("fontSize"),
        createLink: document.queryCommandState("createLink"),
        superscript: document.queryCommandState("superscript"),
    }
}

export const getTextContent = (id)=>{
    return document.getElementById(id)?.innerHTML;
}


import React, {ReactElement} from 'react';
import Div from "../Div/Div";
import "./Layout.scss";
const Layout = (props: {children?: ReactElement| ReactElement[]}) => {
    return (
        <Div className={"layout"} column={true}>
            {props.children && props.children}
        </Div>
    );
};

export default Layout;

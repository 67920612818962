import React, {useEffect, useRef} from 'react';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import ScrollBar from "../components/ScrollBar/ScrollBar";
import Main from "../pages/Main";
import {useParams} from "react-router";

const MainRouter = () => {

    return (
        <BrowserRouter>
            <Routes>
                <Route path="/:p1?/:p2?/:p3?/:p4?" element={<ScrollBar scrollTopOnchangeParams={true} id={"main-scrollbar"}><Main/></ScrollBar>}/>
            </Routes>
        </BrowserRouter>
    );
};

export default MainRouter;

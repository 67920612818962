import React, {useMemo, useState} from "react";
import {BsLink45Deg} from "react-icons/bs";
import Dialog from "../Dialog/Dialog";
import Div from "../Div/Div";
import Input from "../Input/Input";
import Space from "../Space/Space";
import Text from "../Text/Text";
import Button from "../Button/Button";
import {isValidUrl} from "../../helpers";

export const ColorPicker = (props: { onChange: (e) => void, value?: string }) => {

    const [opened, setOpened] = useState(false);
    const colors = [
        '#242424',
        '#2977ff',
        '#FF0000',
        '#079f26',
        '#7b7b7b',
        '#9E6E4F',
    ]

    const {onChange, value = colors[0]} = props;

    return <div>
        <div className={"toolbar-button"} style={{background: value}} onMouseDown={(e) => {
            e.preventDefault();
            setOpened(!opened);
            return false;
        }}></div>
        {opened && <div className="editor-picker">
            {colors.map((v, i) => {
                return <div key={"color-item" + i} onMouseDown={(e) => {
                    e.preventDefault();
                    onChange(v);
                    setOpened(false);
                    return false;
                }} style={{background: v}} className="color-item"></div>
            })}
        </div> || null}
    </div>
}


export const FontSIzePicker = (props: { onChange: (e) => void, value?: string }) => {

    const [opened, setOpened] = useState(false);
    const sizes = [
        {id: "1", name: "12"},
        {id: "2", name: "14"},
        {id: "3", name: "16"},
        {id: "4", name: "18"},
    ]

    const {onChange, value = sizes[0].id} = props;

    const selected: any = useMemo(() => {
        return sizes.find(x => x.id === value) ?? sizes[0].id;
    }, [value]);

    return <div>
        <div className={"toolbar-button font-size-button"} onMouseDown={(e) => {
            e.preventDefault();
            setOpened(!opened);
            return false;
        }}>{selected?.name}</div>
        {opened && <div className="editor-picker">
            {sizes.map((v, i) => {
                return <div key={"font-size-item" + i} onMouseDown={(e) => {
                    e.preventDefault();
                    onChange(v.id);
                    setOpened(false);
                    return false;
                }} className="font-size-item">{v?.name}</div>
            })}
        </div> || null}
    </div>
}


export const LinkPicker = (props: { onChange: (e) => void, value?: { url: string, title: string }, onClick: () => void }) => {

    const [opened, setOpened] = useState(false);

    const {onChange, value, onClick} = props;
    const [inputVal, setInputVal] = useState(value?.url);
    const [inputTitle, setInputTitle] = useState(value?.title);
    const [err, setErr] = useState(false);


    return <div>
        <div className={"toolbar-button font-size-button"} onMouseDown={(e) => {
            e.preventDefault();
            onClick();
            setTimeout(() => {
                setOpened(!opened);
            });
            return false;
        }}><BsLink45Deg/>
        </div>
        {opened && <Dialog size={"xsm"} onClose={() => {
            setOpened(false);
            setInputVal("");
            setInputTitle("");
            setErr(false);
        }} title={"Link"}>
            <Div column alignH={"center"}>
                <Input autofocus placeholder={"Title"} value={inputTitle} onChange={setInputTitle}/>
                <Space size={"xsm"}/>
                <Input autofocus placeholder={"Link"} value={inputVal} onChange={setInputVal}/>
                <Space size={"sm"}/>
                {err && <>
                    <Text color={"red"}>Լրացրեք պարտադիր դաշտերը & valid url</Text>
                    <Space size={"xsm"}/>
                </>}
                <Button title={"Ok"} stretch={"fixed"} onMouseDown={() => {
                    setErr(false);
                    if (inputTitle?.length && isValidUrl(inputVal)) {
                        setInputTitle("");
                        setInputVal("");
                        onChange({title: inputTitle, url: inputVal});
                        setOpened(false);
                        setInputTitle("");
                        setInputVal("");
                    } else {
                        setErr(true);
                    }

                    return false;
                }}/>
            </Div>
        </Dialog> || null}
    </div>
}
import React, {useEffect, useState} from 'react';
import Preloader from "../../components/Preloader/Preloader";
import Text from "../../components/Text/Text";
import Space from "../../components/Space/Space";
import Layout from "../../components/Layout/Layout";
import {conn} from "../../helpers/request/connect";
import Button from "../../components/Button/Button";
import Dialog from "../../components/Dialog/Dialog";
import Div from "../../components/Div/Div";
import ListItem from "../../components/ListItem/ListItem";

const Contacts = () => {

    const [loading, setLoading] = useState(true);
    const [items, setItems] = useState([]);
    const [nDocument, setNDocument] = useState(false);
    const [documentRemove, setDocumentRemove] = useState(false);
    const [documentInfo, setDocumentInfo] = useState<any>({});


    useEffect(() => {
        conn("contacts/getContacts").then((res: any) => {
            if (res.items) setItems(res.items);
            setLoading(false);
        });

    }, [])


    return (
        <Layout>
            <Preloader active={loading}/>
            <Text size={"md"} weight={"bold"}>Հետադաձ կապ</Text>
            <Space/>
            <Div column gap={2}>
                {items.map((val) => {
                    return <ListItem selected={!Number(val.is_read)} hover={true} key={val.id} title={val.name} onClick={() => {
                        setDocumentInfo(val);
                        setNDocument(true);
                       conn("contacts/read", {type: "contacts", id: val.id}).then();
                        setItems(prev=>{return prev.map(v=>{
                            return v.id === val.id? {...v, is_read: "1"} : v
                        })})
                    }}/>
                })}
            </Div>


            {nDocument && <Dialog buttons={{
                right: <Div gap={12}>

                        <Button title={"Փակել"} onClick={() => {
                            setDocumentInfo({});
                            setNDocument(false);
                        }} color={"transparent"}/>

                        <Button title={"Հեռացնել"} onClick={() => {
                            setDocumentRemove(documentInfo.id);
                        }} color={"red"}/>

                </Div>
            }} size={"md"} title={"Հաղորդագրություն"} onClose={() => {
                setNDocument(false);
                setDocumentInfo({});
            }}>

                <Space size={"sm"}/>
                <Div column>
                    <Text color={"grey"}>Անուն</Text>
                    <Text>{documentInfo.name}</Text>
                    <Space/>
                </Div>
                <Div column>
                    <Text color={"grey"}>Էլ.հասցե</Text>
                    <Text>{documentInfo.email}</Text>
                    <Space/>
                </Div>
                <Div column>
                    <Text color={"grey"}>Հաղորդագրություն</Text>
                    <Text wrap={"pre"}>{documentInfo.message}</Text>

                </Div>


            </Dialog>}

            {documentRemove && <Dialog
                buttons={{
                    right: <Div gap={12}>
                        <Button title={"Չեղարկել"} color={"transparent"} onClick={() => setDocumentRemove(false)}/>
                        <Button title={"Հեռացնել"} color={"red"} onClick={() => {
                            conn("contacts/deleteContact", {id: documentInfo.id}).then(() => window.location.reload())
                        }}/>
                    </Div>
                }}
                size={"xsm"} title={"Հեռացնել"} onClose={() => setDocumentRemove(false)}>
                <Text align={"center"}>Դուք ի՞րոք ցանկանում եք հեռացնել <Text
                    weight={"bold"}>{documentInfo.title_hy}?</Text></Text>
            </Dialog>}

        </Layout>
    );
};

export default Contacts;


import * as React from "react";
import {LegacyRef, useEffect, useRef, useState} from "react";
import "./Input.scss";
import {RiCloseFill} from "react-icons/ri";
import classNames from "classnames";

const Input = (props: {
    withoutBorderBottom?: boolean,
    value?: string | number,
    id?: string,
    name?: string,
    placeholder?: string,
    leftIcon?: JSX.Element,
    rightIcon?: JSX.Element,
    onRightIconClick?: () => void,
    onChange?: (e: any) => void,
    onEnter?: (e) => void,
    label?: string,
    helpText?: string,
    sideLabel?: string,
    error?: boolean,
    success?: boolean,
    disabled?: boolean,
    onClear?: () => void,
    clearButton?: boolean,
    size?: "sm" | "md" | "lg",
    autofocus?: boolean,
    onFocus?: (e?: any) => void,
    onFocusOut?: () => void,
    onClick?: () => void,
    className?: string,
    pattern?: string,
    autocomplete?: boolean
    type?: "text" | "number" | "password" | "email" | "tel" | "textarea" | "date",
    stretch?: "full"| "fixed"
}) => {
    const {
        withoutBorderBottom,
        value = "",
        placeholder = "",
        leftIcon,
        rightIcon,
        sideLabel,
        helpText,
        error,
        success,
        disabled,
        label,
        type = "text",
        onClear,
        clearButton,
        size = "sm",
        autofocus,
        onChange = () => {
        },
        onEnter = () => {
        },
        onClick = () => {
        },
        onRightIconClick = () => {
        },
        onFocusOut,
        onFocus,
        className = "",
        pattern,
        autocomplete,
        id,
        name,
        stretch = "fixed"
    } = props;

    const [_value, _setValue] = useState(value);
    const inputRef: any = useRef();
    const inputContentRef: any = useRef();
    useEffect(() => {
        _setValue(value);
    }, [value])

    const inputClasses = classNames("input", className, {
        "input-error": error,
        "no-border": withoutBorderBottom,
        "input-success": !error && success,
        [`size-${size}`]: true,
        [`stretch-${stretch}`]: true,
        "disabled": disabled,
        "textarea": type === "textarea",
        "border-bottom": false
    });


    useEffect(() => {
        if (autofocus && inputContentRef?.current) {
            // @ts-ignore
            inputContentRef?.current?.querySelector("input")?.focus();
        }
    }, [])

    return <div ref={inputContentRef} className={"input-container"}>
        <div className={inputClasses} onClick={onClick}>
            {sideLabel && <div className={"side-label"}>{sideLabel}</div>}
            <div className={"input-content"}>
                {label && <div className={"input-label"}>{label}</div>}
                <div className={"input-in"}>
                    {leftIcon && <div className={"input-icon"}>{leftIcon}</div>}
                    {type === "textarea" ? <textarea
                        id={id}
                        name={name}
                        onFocus={onFocus}
                        onBlur={onFocusOut}
                        ref={inputRef}
                        autoComplete={autocomplete ? "on" : "off"}
                        onKeyDown={(e)=>{
                            if(e.key ==="Enter" && !e.shiftKey){
                                onEnter(e);
                            }
                        }}
                        onChange={(e) => {
                            _setValue(e.target.value);
                            onChange(e.target.value);

                        }}
                        placeholder={placeholder}
                        value={_value}
                    ></textarea> : <input
                        id={id}
                        name={name}
                        type={type}
                        onFocus={onFocus}
                        onBlur={onFocusOut}
                        ref={inputRef}
                        pattern={pattern}
                        inputMode={type === "number" ? "numeric" : undefined}
                        autoComplete={autocomplete ? "on" : "off"}
                        onKeyDown={(e)=>{
                            if(e.key ==="Enter" && !e.shiftKey){
                               onEnter(e);
                            }
                        }}
                        onChange={(e) => {
                            _setValue(e.target.value);
                            onChange(e.target.value);
                        }}
                        value={_value} placeholder={placeholder}/>}
                    {rightIcon && <div onClick={onRightIconClick} className={"input-icon r"}>{rightIcon}</div>}
                    {(clearButton && !rightIcon && _value.toString()?.length > 0) && (
                        <div className={"input-icon r clear"} onClick={() => {
                            if (onClear) onClear();
                            // @ts-ignore
                            inputRef?.current?.focus();
                            _setValue("");
                        }}>{<RiCloseFill color={"#a3acb9"}/>}</div>)}
                </div>
                {helpText && <div className={"input-help"}>{helpText}</div>}
            </div>
        </div>
    </div>
}
export default Input;
import React, {useEffect, useMemo, useState} from 'react';
import Preloader from "../../components/Preloader/Preloader";
import Text from "../../components/Text/Text";
import Space from "../../components/Space/Space";
import Layout from "../../components/Layout/Layout";
import {getImage, sortBy} from "../../helpers";
import Uploader from "../../components/Uploader/Uploader";
import {conn, uploadFile} from "../../helpers/request/connect";
import Editor from "../../components/Editor/Editor";
import Button from "../../components/Button/Button";
import Dialog from "../../components/Dialog/Dialog";
import LangTabs from "../../components/LangTabs/LangTabs";
import Input from "../../components/Input/Input";
import Div from "../../components/Div/Div";
import ListItem from "../../components/ListItem/ListItem";
import {Select} from "../../components/Select/Select";
import {useParams} from "react-router";
import {Simulate} from "react-dom/test-utils";
import error = Simulate.error;

const MediaImages = () => {

    const [loading, setLoading] = useState(true);
    const [info, setInfo] = useState<any>({bold: false});
    const [items, setItems] = useState([]);
    const [regions, setRegions] = useState([]);
    const [nDocument, setNDocument] = useState(false);
    const [documentRemove, setDocumentRemove] = useState(false);
    const [documentInfo, setDocumentInfo] = useState<any>({});
    const [types, setTypes] = useState([]);

    useEffect(() => {
        conn("media/getMedia", {type: params.p2}).then((res: any) => {
            if (res.items) setItems(res.items);
            setLoading(false);
        });

        conn("regions/get").then((res: any) => {
            if (res.items.length) setRegions(sortBy(res.items, "sort"));
            setLoading(false);
        });

        conn("types/get").then((res: any) => {
            if (res.items.length) setTypes(sortBy(res.items, "sort"));
        });
    }, [])


    const saveDocument = (data) => {
        conn("media/saveMedia", data).then((res) => {
            window.location.reload();
        });
    }

    const params = useParams();

    const is_video = useMemo(()=>{
        return params.p2 === "films" || params.p2 === "filming" || params.p2 === "programs";
    }, [params.p2])

    const title = useMemo(()=>{
        switch (params.p2){
            case "plans":
                return "Հատակագծեր";
            case "sketches":
                return "Գրչանկարներ";
            case "reorganizations":
                return "Վերակազմություններ";
            case "metrics":
                return "Չափագրություններ";
            case "films":
                return "Ֆիլմեր";
            case "filming":
                return "Նկարահանումներ";
            case "programs":
                return "Հաղորդումներ";
            default:
                return "Լուսանկարներ";
        }
    }, [params?.p2])



    return (
        <Layout>
            <Preloader active={loading}/>
            <Text size={"md"} weight={"bold"}>{title}</Text>

            <Space size={"sm"}/>
            <Button color={"green"} title={"Ավելացնել նորը"} stretch={"fixed"} onClick={() => setNDocument(true)}/>
            <Space/>
            <Div column>
                {items.map((val) => {
                    return <ListItem size={"lg"} leftIcon={<img src={getImage(val.image, is_video? "youtube": "media")}/>} hover={true} key={val.id} title={val.title_hy} onClick={() => {
                        setDocumentInfo(val);
                        setNDocument(true);
                    }}/>
                })}
            </Div>


            {nDocument && <Dialog buttons={{
                right: <Div gap={12}>
                    <Button title={"Պահպանել"} color={"green"} stretch={"fixed"} onClick={() => {
                        if(!documentInfo.image) alert("Select image");
                        else {
                            setLoading(true);
                            if (documentInfo.image && typeof documentInfo.image !== "string") {
                                uploadFile(documentInfo.image, "media").then((res: any) => {
                                  if(res.error){
                                      alert(`Error: ${res.error === 2? "File format error": "File size error"}`)
                                      setLoading(false);
                                  }else{
                                      saveDocument({...documentInfo, image: res.response, type: params.p2});
                                  }
                                })
                            } else {
                                saveDocument({...documentInfo, type: params.p2});
                            }
                            setDocumentInfo({});
                        }
                    }}/>
                    {documentInfo.id &&
                        <Button title={"Հեռացնել"} onClick={() => setDocumentRemove(true)} color={"red"}/>}
                </Div>
            }} title={"Images"} onClose={() => {
                setNDocument(false);
                setDocumentInfo({});
            }}>
                {is_video ? <>
                        <iframe width="100%" height="315" src={"https://www.youtube.com/embed/" + documentInfo?.image}
                                title="YouTube video player" frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                allowFullScreen></iframe>
                        <Input placeholder={"Youtube url"} value={documentInfo.image} onChange={(e)=>setDocumentInfo(prev=>{return{...prev, image: e}})}/></> :
                    <><Uploader size={"l"}
                           value={documentInfo.image ? (typeof documentInfo.image === "string" ? getImage(documentInfo.image, "media") : URL.createObjectURL(documentInfo.image)) : undefined}
                           onChange={(e) => setDocumentInfo(prev => {
                               return {...prev, image: e}
                           })}/>
                        <Space size={"sm"}/>
                        <Text color={"grey-middle"}>Շրջան</Text>
                        <Space size={"xsm"}/>
                        <Select
                            onChange={(e)=>{
                                setDocumentInfo(prev=>{return {...prev, region: e.id, region2: null}});
                            }}
                            selected={documentInfo.region}
                            options={regions?.filter(x => !x.parent_id || x.parent_id === "0")?.map(v=>{return {title: v.title_hy, id: v.id}})}/>
                        <Space size={"sm"}/>
                        <Text color={"grey-middle"}>Բնակավայր</Text>
                        <Space size={"xsm"}/>
                        <Select
                            search={true}
                            onChange={(e)=>{
                                setDocumentInfo(prev=>{return{...prev, region2: e.id}});
                            }}
                            options={regions?.filter(x => x.parent_id === documentInfo.region)?.map(v=>{return {title: v.title_hy, id: v.id}})}
                            selected={documentInfo.region2}/>
                        <Space size={"sm"}/>
                        <Text color={"grey-middle"}>Հուշարձանի տիպը</Text>
                        <Space size={"xsm"}/>
                        <Select
                            onChange={(e)=>{
                                setDocumentInfo(prev=>{return{...prev, monument_type: e.id}});
                            }}
                            options={types?.map(v=>{return {title: v.title_hy, id: v.id}})}
                            selected={documentInfo.monument_type}/>
                    </>}

                <Space size={"sm"}/>
                <LangTabs tabs={[

                    {
                        title: "Հայերեն",
                        content: <>
                            <Input label={"Վերնագիր"} placeholder={"Վերնագիր"} value={documentInfo.title_hy}
                                          onChange={(e) => setDocumentInfo(prev => {
                                              return {...prev, title_hy: e}
                                          })}/>
                            <Space size={"sm"}/>
                            <Text size={"xsm"} color={"grey"}>Նկարագրություն</Text>
                            <Space size={"xsm"}/>
                            <Editor size={"sm"} toolbar={false} key={"text-hy-media"} value={documentInfo.text_hy}
                                    onChange={(e) => setDocumentInfo(prev => {
                                        return {...prev, text_hy: e}
                                    })}/>
                        </>
                    },
                    {
                        title: "English",
                        content: <>
                            <Input label={"Title"} placeholder={"Title"} value={documentInfo.title_en}
                                          onChange={(e) => setDocumentInfo(prev => {
                                              return {...prev, title_en: e}
                                          })}/>
                            <Space size={"sm"}/>
                            <Text size={"xsm"} color={"grey"}>Description</Text>
                            <Space size={"xsm"}/>
                            <Editor size={"sm"} toolbar={false} key={"text-en-media"} value={documentInfo.text_en}
                                    onChange={(e) => setDocumentInfo(prev => {
                                        return {...prev, text_en: e}
                                    })}/>
                        </>
                    }
                ]}/>

            </Dialog>}
            {documentRemove && <Dialog
                buttons={{
                    right: <Div gap={12}>
                        <Button title={"Չեղարկել"} color={"transparent"} onClick={() => setDocumentRemove(false)}/>
                        <Button title={"Հեռացնել"} color={"red"} onClick={() => {
                            conn("media/removeMedia", {id: documentInfo.id}).then(() => window.location.reload())
                        }}/>
                    </Div>
                }}
                size={"xsm"} title={"Հեռացնել"} onClose={() => setDocumentRemove(false)}>
                <Text align={"center"}>Դուք ի՞րոք ցանկանում եք հեռացնել <Text
                    weight={"bold"}>{documentInfo.title_en}?</Text></Text>
            </Dialog>}
        </Layout>
    );
};

export default MediaImages;


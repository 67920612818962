import React, {useEffect, useMemo, useRef, useState} from 'react';
import Preloader from "../../components/Preloader/Preloader";
import Text from "../../components/Text/Text";
import Space from "../../components/Space/Space";
import Layout from "../../components/Layout/Layout";
import {getImage, sortBy} from "../../helpers";
import Uploader from "../../components/Uploader/Uploader";
import {conn, uploadFile} from "../../helpers/request/connect";
import Editor from "../../components/Editor/Editor";
import Button from "../../components/Button/Button";
import Dialog from "../../components/Dialog/Dialog";
import LangTabs from "../../components/LangTabs/LangTabs";
import Input from "../../components/Input/Input";
import Div from "../../components/Div/Div";
import ListItem from "../../components/ListItem/ListItem";
import {Select} from "../../components/Select/Select";
import {MdDelete} from "react-icons/md";
import {FaFileAlt} from "react-icons/fa";

const Literature = () => {

    const [loading, setLoading] = useState(true);
    const [info, setInfo] = useState<any>({bold: false});
    const [items, setItems] = useState([]);
    const [regions, setRegions] = useState([]);
    const [nDocument, setNDocument] = useState(false);
    const [documentRemove, setDocumentRemove] = useState(false);
    const [documentInfo, setDocumentInfo] = useState<any>({})
    const [fileEdit, setFileEdit] = useState(false);
    const [fileEditInfo, setFileEditInfo] = useState<any>({});
    const [files, setFiles] = useState([]);
    const [fileRemove, setFileRemove] = useState(false);

    const fileRef = useRef();


    useEffect(() => {
        conn("literatures/get").then((res: any) => {
            if (res.items) setItems(res.items);
            if (res.response) {
                setInfo({image: res.response.image, text: res.response.title_en});
            }
            setLoading(false);
        });

    }, [])


    const saveDocument = (data) => {
        conn("literatures/saveItem", data).then((res) => {
           window.location.reload();
        });
    }

    useEffect(()=>{
            if(documentInfo.id) {
                setLoading(true);
                conn("literatures/getFiles", {id: documentInfo.id}).then((res: any) => {
                    setFiles(res.response);
                    setLoading(false);
                });
            }
    }, [documentInfo?.id]);

    const saveFile = (data) => {
        conn("literatures/saveFile", data).then((res:any) => {
            if(res.response){
                setDocumentInfo(prev=>{return{...prev, files: prev.files? prev.files + "," + res.response : res.response}});
               setFileEdit(false);
               setFileEditInfo({});
            }else alert("Error: 11255");
            setLoading(false);
        });
    }

    return (
        <Layout>
            <Preloader active={loading}/>
            <Text size={"md"} weight={"bold"}>Գրականություն</Text>
            <Space size={"sm"}/>
            <Text size={"xmd"} color={"grey-middle"}>Նկար</Text>
            <Uploader
                value={info.image ? (typeof info.image === "string" ? getImage(info.image, "cover") : URL.createObjectURL(info.image)) : undefined}
                disabled={loading} type={"image"} size={"l"} onChange={(e) => {
                if (e) {
                    setLoading(true);
                    uploadFile(e, "cover").then((res: any) => {
                        conn("literatures/save", {image: res.response}).then(() => window.location.reload());
                    });
                }
            }}/>
            <Space size={"sm"}/>
            <Button color={"green"} title={"Ավելացնել նոր գրականութոյւն"} onClick={() => setNDocument(true)}/>
            <Space/>
            <Div column>
                {items.map((val) => {
                    return <ListItem hover={true} key={val.id} title={val.title_hy} onClick={() => {
                        setDocumentInfo(val);
                        setNDocument(true);
                    }}/>
                })}
            </Div>


            {nDocument && <Dialog buttons={{
                right: <Div gap={12}>
                    <Button title={"Պահպանել"} color={"green"} stretch={"fixed"} onClick={() => {

                        if (!documentInfo.image) alert("Select image");
                        else if (!documentInfo.title_en?.length || !documentInfo.title_hy?.length) alert("Լրացրեք պարտադիր դաշտերը");
                        else {
                            setLoading(true);
                            if (documentInfo.image && typeof documentInfo.image !== "string") {
                                uploadFile(documentInfo.image, "cover", true).then((res: any) => {
                                    saveDocument({...documentInfo, image: res.response, files: files});
                                })
                            } else {
                                saveDocument({...documentInfo, files: files});
                            }
                            setDocumentInfo({});
                        }
                    }}/>
                    {documentInfo.id &&
                        <Button title={"Հեռացնել"} onClick={() => setDocumentRemove(true)} color={"red"}/>}
                </Div>
            }} size={"md"} title={"Գրականություն"} onClose={() => {
                setNDocument(false);
                setDocumentInfo({});
                setFiles([]);
            }}>
                <Uploader size={"l"}
                          value={documentInfo.image ? (typeof documentInfo.image === "string" ? getImage(documentInfo.image, "cover") : URL.createObjectURL(documentInfo.image)) : undefined}
                          onChange={(e) => setDocumentInfo(prev => {
                              return {...prev, image: e}
                          })}/>

                <Space size={"sm"}/>
                <LangTabs tabs={[

                    {
                        title: "Հայերեն",
                        content: <>
                            <Text size={"xmd"} weight={"bold"}>Նկարի տակագիր</Text>
                            <Space size={"xsm"}/>
                            <Input stretch={"full"} placeholder={"Տակագիր"} value={documentInfo.caption_hy}
                                   onChange={(e) => setDocumentInfo(prev => {
                                       return {...prev, caption_hy: e}
                                   })}/>
                            <Space/>
                            <Input label={"Վերնագիր"} placeholder={"Վերնագիր"} value={documentInfo.title_hy}
                                          onChange={(e) => setDocumentInfo(prev => {
                                              return {...prev, title_hy: e}
                                          })}/>
                            <Space size={"sm"}/>
                            <Text size={"xsm"} color={"grey"}>Նկարագրութնուն</Text>
                            <Space size={"xsm"}/>
                            <Editor key={"text-hy"} value={documentInfo.text_hy}
                                    onChange={(e) => setDocumentInfo(prev => {
                                        return {...prev, text_hy: e}
                                    })}/>
                        </>
                    },
                    {
                        title: "English",
                        content: <>
                            <Text size={"xmd"} weight={"bold"}>Image caption</Text>
                            <Space size={"xsm"}/>
                            <Input stretch={"full"} placeholder={"Caption"} value={documentInfo.caption_en}
                                   onChange={(e) => setDocumentInfo(prev => {
                                       return {...prev, caption_en: e}
                                   })}/>
                            <Space/>
                            <Input label={"Title"} placeholder={"Title"} value={documentInfo.title_en}
                                          onChange={(e) => setDocumentInfo(prev => {
                                              return {...prev, title_en: e}
                                          })}/>
                            <Space size={"sm"}/>
                            <Text size={"xsm"} color={"grey"}>Description</Text>
                            <Space size={"xsm"}/>
                            <Editor key={"text-en"} value={documentInfo.text_en}
                                    onChange={(e) => setDocumentInfo(prev => {
                                        return {...prev, text_en: e}
                                    })}/>
                        </>
                    },
                ]}/>
                <Space/>
                <Text color={"grey"}>Ֆայլեր</Text>

                {files.length ? <>
                {files.map(file=>{
                return <ListItem leftIcon={<FaFileAlt size={32}/>} hover={true} onClick={()=>{
                    setFileEdit(true);
                    setFileEditInfo(file);
                }} title={file.title_hy}/>
                })}

                </>: <Text align={"center"} color={"grey"}>Ընտրված ֆայլեր չկան</Text>}
                <Space/>
                <Button onClick={() => setFileEdit(true)} size={"xsm"} color={"green"} title={"Ավելացնել ֆայլ"}/>
            </Dialog>}

            {fileEdit && <Dialog
                title={"Ֆայլ"}
                onClose={() => {
                    setFileEdit(false);
                    setFileEditInfo({});
                }}
                buttons={{
                    right: <Div gap={12}>
                        <Button title={"Պահպանել"} color={"green"} onClick={() => {
                            if (!fileEditInfo.file) {
                                alert("Ներբեռնեք ֆայլը");
                            } else if (!fileEditInfo.title_hy || !fileEditInfo.title_en) {
                                alert("Լրացրեք պարտադիր դաշտերը");
                            } else {
                                if(fileEditInfo.id){
                                    setFiles(prev=>{return prev.map(x => {
                                        return x.id === fileEditInfo.id? fileEditInfo : x
                                    })});
                                }else if(fileEditInfo.tmp_key){
                                    setFiles(prev=>{return prev.map(x => {
                                        return x.tmp_key === fileEditInfo.tmp_key? fileEditInfo : x
                                    })});
                                }else{
                                   setFiles(prev=>{return [...prev, {...fileEditInfo, tmp_key: new Date().getTime()}]});
                                }
                                setFileEdit(false);
                                setFileEditInfo({});
                            }

                        }}/>
                        {fileEditInfo.id || fileEditInfo.tmp_key ? <Button title={"Հեռացնել"} color={"red"} onClick={()=> {

                            setFiles(prev=>{return prev.filter(x => fileEditInfo.id? (x.id !== fileEditInfo.id): (x.tmp_key !== fileEditInfo.tmp_key))});
                            setFileEditInfo({});
                            setFileEdit(false);
                        }}/> : null}
                        <Button title={"Չեղարկել"} color={"grey"} onClick={() => {
                            setFileEditInfo({});
                            setFileEdit(false);
                        }}/>
                    </Div>
                }}
            >

                <LangTabs tabs={[

                    {
                        title: "Հայերեն",
                        content: <><Input label={"Ֆայլի անվանում"} placeholder={"Ֆայլի անվանում"}
                                          value={fileEditInfo.title_hy}
                                          onChange={(e) => setFileEditInfo(prev => {
                                              return {...prev, title_hy: e}
                                          })}/>
                        </>
                    },
                    {
                        title: "English",
                        content: <><Input label={"File title"} placeholder={"File title"} value={fileEditInfo.title_en}
                                          onChange={(e) => setFileEditInfo(prev => {
                                              return {...prev, title_en: e}
                                          })}/>
                        </>
                    },
                ]}/>
                <Space/>
                {fileEditInfo.file && <>
                    <ListItem leftIcon={<FaFileAlt size={32}/>} title={fileEditInfo.file}
                              rightIcon={<Button iconLeft={<MdDelete/>} onClick={() => setFileEditInfo(prev => {
                                  return {...prev, file: null}
                              })} color={"red"}/>}/>
                </> || <>
                    <Button title={"Ներբեռնել"} color={"green"} onClick={() => {
                        // @ts-ignore
                        fileRef?.current?.click();
                    }}/>
                </>}

                <input type={"file"} className={"hidden"} onChange={(e) => {
                    setLoading(true);

                    uploadFile(e.target.files[0], "file").then((res: any) => {
                        if(res.error){
                            alert(res.error === 1? "Ֆայլի չափը չպետք է գերազանցի 100ՄԲ": "Անյահտ սխալ: 1");
                            setLoading(false);
                        }else if (res.response) {
                            setFileEditInfo(prev=>{return{...prev, file: res.response}});
                            setLoading(false);
                        }else{
                            alert("Անյահտ սխալ: 2");
                            setLoading(false);
                        }
                        // @ts-ignore
                        setTimeout(() => fileRef.current.value = null, 200);
                    });
                }} ref={fileRef}/>
            </Dialog>}

            {documentRemove && <Dialog
                buttons={{
                    right: <Div gap={12}>
                        <Button title={"Չեղարկել"} color={"transparent"} onClick={() => setDocumentRemove(false)}/>
                        <Button title={"Հեռացնել"} color={"red"} onClick={() => {
                            conn("literatures/remove", {id: documentInfo.id}).then(() => window.location.reload())
                        }}/>
                    </Div>
                }}
                size={"xsm"} title={"Հեռացնել"} onClose={() => setDocumentRemove(false)}>
                <Text align={"center"}>Դուք ի՞րոք ցանկանում եք հեռացնել <Text
                    weight={"bold"}>{documentInfo.title_hy}?</Text></Text>
            </Dialog>}

        </Layout>
    );
};

export default Literature;


import React, {useEffect, useMemo, useState} from 'react';
import Preloader from "../../components/Preloader/Preloader";
import Text from "../../components/Text/Text";
import Space from "../../components/Space/Space";
import Layout from "../../components/Layout/Layout";
import {getImage, sortBy} from "../../helpers";
import Uploader from "../../components/Uploader/Uploader";
import {conn, uploadFile} from "../../helpers/request/connect";
import Editor from "../../components/Editor/Editor";
import Button from "../../components/Button/Button";
import Dialog from "../../components/Dialog/Dialog";
import LangTabs from "../../components/LangTabs/LangTabs";
import Input from "../../components/Input/Input";
import Div from "../../components/Div/Div";
import ListItem from "../../components/ListItem/ListItem";
import {Select} from "../../components/Select/Select";
import ListPicker from "../../components/ListPicker/ListPicker";

const img_types = ["metrics", "plans", "sketches", "photos", "reorganizations"];
const video_types = ["films", "filming", "programs"];

const DocumentsForms = () => {

    const [loading, setLoading] = useState(true);
    const [info, setInfo] = useState<any>({bold: false});
    const [items, setItems] = useState([]);
    const [regions, setRegions] = useState([]);
    const [nDocument, setNDocument] = useState(false);
    const [documentRemove, setDocumentRemove] = useState(false);
    const [documentInfo, setDocumentInfo] = useState<any>({form: {}});
    const [formValues, setFormValues] = useState({});
    const [types, setTypes] = useState([]);
    const [forms, setForms] = useState([]);
    const [section, setSection] = useState([]);
    const [media, setMedia] = useState([]);



    useEffect(() => {
        conn("forms/get").then((res: any) => {
            if (res.items) setItems(res.items);
            if (res.response) {
                setInfo({image: res.response.image, text: res.response.title_en});
            }
            if (res.forms) setForms(res.forms);

            setLoading(false);
        });
        conn("regions/get").then((res: any) => {
            if (res.items.length) setRegions(sortBy(res.items, "sort"));
            setLoading(false);
        });

        conn("types/get").then((res: any) => {
            if (res.items.length) setTypes(sortBy(res.items, "sort"));
        });

        conn("document-form-items/getSections").then((res: any) => {
            if (res.items) setSection(res.items);
        });

        conn("media/getMedia").then((res: any) => {
            if (res.items) setMedia(res.items);
        });

    }, [])

    useEffect(() => {
        if (documentInfo?.id) {
            setLoading(true);
            conn("forms/formValues", {id: documentInfo?.id}).then((res:any) => {
                setLoading(false);
                if (res?.items.length) {
                    const obj = {};
                    res.items.forEach(v=>{
                       obj[v.document_form_item_id] = v;
                    });
                    setFormValues(obj);
                }
            });
            }
    }, [documentInfo?.id]);

    const saveDocument = (data) => {
         conn("forms/saveItem", data).then((res) => {
             window.location.reload();
         });
    }


    return (
        <Layout>
            <Preloader active={loading}/>
            <Text size={"md"} weight={"bold"}>Վավերագրեր</Text>
            <Space size={"sm"}/>
            <Text size={"xmd"} color={"grey-middle"}>Նկար</Text>
            <Uploader
                value={info.image ? (typeof info.image === "string" ? getImage(info.image, "cover") : URL.createObjectURL(info.image)) : undefined}
                disabled={loading} type={"image"} size={"l"} onChange={(e) => {
                if (e) {
                    setLoading(true);
                    uploadFile(e, "cover").then((res: any) => {
                        conn("forms/save", {image: res.response}).then(() => window.location.reload());
                    });
                }
            }}/>
            <Space size={"sm"}/>
            <Button color={"green"} title={"Ավելացնել նորը"} stretch={"fixed"} onClick={() => setNDocument(true)}/>
            <Space/>
            <Div column>
                {items.map((val) => {
                    return <ListItem hover={true} key={val.id} title={val.title_hy} onClick={() => {
                        setDocumentInfo(val);
                        setNDocument(true);
                    }}/>
                })}
            </Div>


            {nDocument && <Dialog buttons={{
                right: <Div gap={12}>
                    <Button title={"Պահպանել"} color={"green"} stretch={"fixed"} onClick={() => {

                        if (!documentInfo.title_en?.length || !documentInfo.title_hy?.length) alert("Լրացրեք պարտադիր դաշտերը");
                        else {

                            if (documentInfo.image && typeof documentInfo.image !== "string") {
                                uploadFile(documentInfo.image, "cover", true).then((res: any) => {
                                    saveDocument({...documentInfo, form: formValues, image: res.response});
                                });
                            }else{
                                saveDocument({...documentInfo, form: formValues});
                            }


                            setLoading(true);
                        }
                    }}/>
                    {documentInfo.id &&
                        <Button title={"Հեռացնել"} onClick={() => setDocumentRemove(true)} color={"red"}/>}
                </Div>
            }} size={"full"} title={"Վավերագիր"} onClose={() => {
                setNDocument(false);
                setDocumentInfo({});
            }}>
                <Uploader size={"l"}
                          value={documentInfo.image ? (typeof documentInfo.image === "string" ? getImage(documentInfo.image, "cover") : URL.createObjectURL(documentInfo.image)) : undefined}
                          onChange={(e) => setDocumentInfo(prev => {
                              return {...prev, image: e}
                          })}/>
                <Space size={"sm"}/>
                <LangTabs tabs={[

                    {
                        title: "Հայերեն",
                        content: <>
                            <Text size={"xmd"} weight={"bold"}>Նկարի տակագիր</Text>
                            <Space size={"xsm"}/>
                            <Input stretch={"full"} placeholder={"Տակագիր"} value={documentInfo.caption_hy}
                                   onChange={(e) => setDocumentInfo(prev => {
                                       return {...prev, caption_hy: e}
                                   })}/>
                        </>
                    },
                    {
                        title: "English",
                        content: <>
                            <Text size={"xmd"} weight={"bold"}>Image caption</Text>
                            <Space size={"xsm"}/>
                            <Input stretch={"full"} placeholder={"Caption"} value={documentInfo.caption_en}
                                   onChange={(e) => setDocumentInfo(prev => {
                                       return {...prev, caption_en: e}
                                   })}/>
                        </>
                    }
                ]}/>
                <Space size={"sm"}/>
                <Text color={"grey-middle"}>Շրջան</Text>
                <Space size={"xsm"}/>
                <Select
                    onChange={(e)=>{
                        setDocumentInfo(prev=>{return {...prev, region: e.id, region2: null}});
                    }}
                    selected={documentInfo.region}
                    options={regions?.filter(x => !x.parent_id || x.parent_id === "0")?.map(v=>{return {title: v.title_hy, id: v.id}})}/>
                <Space size={"sm"}/>
                <Text color={"grey-middle"}>Բնակավայր</Text>
                <Space size={"xsm"}/>
                <Select
                    search={true}
                    onChange={(e)=>{
                        setDocumentInfo(prev=>{return{...prev, region2: e.id}});
                    }}
                    options={regions?.filter(x => x.parent_id === documentInfo.region)?.map(v=>{return {title: v.title_hy, id: v.id}})}
                    selected={documentInfo.region2}/>
                <Space size={"sm"}/>
                <Text color={"grey-middle"}>Հուշարձանի տիպը</Text>
                <Space size={"xsm"}/>
                <Select
                    onChange={(e)=>{
                        setDocumentInfo(prev=>{return{...prev, type: e.id}});
                    }}
                    options={types?.map(v=>{return {title: v.title_hy, id: v.id}})}
                    selected={documentInfo.type}/>
                <Space size={"sm"}/>
                <Text color={"grey-middle"}>Կոորդինատներ</Text>
                <Space size={"xsm"}/>
                <Input stretch={"fixed"} placeholder={"Կոորդինատներ"} value={documentInfo.coordinates}
                       onChange={(e) => setDocumentInfo(prev=>{return{...prev, coordinates: e}})}/>
                <Space size={"lg"}/>
                <LangTabs tabs={[

{
title: "Հայերեն",
content: <>
    <Space size={"sm"}/>
    <Text color={"grey-middle"}>Անվանումը</Text>
    <Space size={"xsm"}/>
    <Input placeholder={"Վերնագիր"} value={documentInfo.title_hy}
                  onChange={(e) => setDocumentInfo(prev => {
                      return {...prev, title_hy: e}
                  })}/>
{sortBy(section, "sort").map(sec=>{
    return <div key={"section-" + sec.id}>
        <Space size={"lg"}/>
        <Text weight={"bold"} size={"xmd"}>{sec.title_hy}</Text>
        {sortBy(forms, "sort")?.filter(x => x.section === sec.id)?.map(form => {
            return <Div column key={"form-item" + form.id}>
                <Space size={"md"}/>
                <Text color={"grey-middle"}>{form.title_hy}</Text>
                <Space size={"xsm"}/>
                {form.input_type === "input" &&
                    <Input stretch={"full"} placeholder={form.title_hy} value={formValues?.[form.id]?.text_hy}
                           onChange={(e) => setFormValues(prev => {
                               return {...prev, [`${form.id}`]: {...prev?.[form.id], text_hy: e}}
                           })}/>
                    || form.input_type === "textarea" &&
                    <Editor key={"text-hy"} value={formValues?.[form.id]?.text_hy}
                            onChange={(e) => setFormValues(prev => {
                                return {...prev, [`${form.id}`]: {...prev?.[form.id], text_hy: e}}
                            })}/>
                    || form.input_type === "region" &&
                    <Select
                        onChange={(e)=>{
                            setDocumentInfo(prev=>{return {...prev, region: e.id, region2: null}});
                        }}
                        selected={documentInfo.region}
                        options={regions?.filter(x => !x.parent_id || x.parent_id === "0")?.map(v=>{return {title: v.title_hy, id: v.id}})}/>
                    || form.input_type === "location" &&
                    <Select
                        search={true}
                        onChange={(e)=>{
                            setDocumentInfo(prev=>{return{...prev, region2: e.id}});
                        }}
                        options={regions?.filter(x => x.parent_id === documentInfo.region)?.map(v=>{return {title: v.title_hy, id: v.id}})}
                        selected={documentInfo.region2}/>
                    || form.input_type === "types" &&
                    <Select
                        onChange={(e)=>{
                            setDocumentInfo(prev=>{return{...prev, type: e.id}});
                            setFormValues(prev => {
                                return {...prev, [`${form.id}`]: {...prev?.[form.id], text_hy: e.id}}
                            })
                        }}
                        options={types?.map(v=>{return {title: v.title_hy, id: v.id}})}
                        selected={documentInfo.type}/>
                    || img_types.indexOf(form.input_type) > -1 &&
                    <ListPicker options={{viewImage: true}} lang={"hy"} items={media.filter(x => x.type === form.input_type)?.map(x => {return {...x, image: getImage(x.image, "media")}})} onChange={(e)=>setFormValues(prev=>{return{...prev, [`${form.id}`]: {...prev?.[form.id], text_hy: e}}})} value={formValues?.[form.id]?.text_hy}/>
                    || video_types.indexOf(form.input_type) > -1 &&
                    <ListPicker options={{viewImage: true}} lang={"hy"} items={media.filter(x => x.type === form.input_type)?.map(x => {return {...x, image: "https://i.ytimg.com/vi_webp/"+ x.image +"/sddefault.webp"}})} onChange={(e)=>setFormValues(prev=>{return{...prev, [`${form.id}`]: {...prev?.[form.id], text_hy: e}}})} value={formValues?.[form.id]?.text_hy}/>}
            </Div>
        })}

        <Space line={true}/>
    </div>
})}



</>
                    },
                    {
                        title: "English",
                        content: <>
                            <Space size={"sm"}/>
                            <Text color={"grey-middle"}>Title</Text>
                            <Space size={"xsm"}/>
                            <Input placeholder={"Title"} value={documentInfo.title_en}
                                          onChange={(e) => setDocumentInfo(prev => {
                                              return {...prev, title_en: e}
                                          })}/>
{sortBy(section, "sort").map(sec=>{
                            return <div key={"section-" + sec.id}>
                                <Space size={"lg"}/>
                                <Text weight={"bold"} size={"xmd"}>{sec.title_en}</Text>
{sortBy(forms, "sort").filter(x => x.section === sec.id)?.map(form => {
    return <Div column key={"form-item" + form.id}>
        <Space size={"sm"}/>
        <Text size={"xmd"} color={"grey-middle"}>{form.title_en}</Text>
        <Space size={"xsm"}/>
        {form.input_type === "input" &&
            <Input placeholder={form.title_en} value={formValues?.[form.id]?.text_en}
                   onChange={(e) => setFormValues(prev => {
                       return {...prev, [`${form.id}`]: {...prev?.[form.id], text_en: e}}
                   })}/>
            || form.input_type === "textarea" &&
            <Editor key={"text-en" + form.id} value={formValues?.[form.id]?.text_en}
                    onChange={(e) => setFormValues(prev => {
                        return {...prev, [`${form.id}`]: {...prev?.[form.id], text_en: e}}
                    })}/>
            || form.input_type === "region" &&
            <Select
                onChange={(e)=>{
                    setDocumentInfo(prev=>{return {...prev, region: e.id, region2: null}});
                }}
                selected={documentInfo.region}
                options={regions?.filter(x => !x.parent_id || x.parent_id === "0")?.map(v=>{return {title: v.title_en, id: v.id}})}/>
            || form.input_type === "location" &&
            <Select
                search={true}
                onChange={(e)=>{
                    setDocumentInfo(prev=>{return{...prev, region2: e.id}});
                }}
                options={regions?.filter(x => x.parent_id === documentInfo.region)?.map(v=>{return {title: v.title_en, id: v.id}})}
                selected={documentInfo.region2}/>
            || form.input_type === "types" &&
            <Select
                onChange={(e)=>{
                    setDocumentInfo(prev=>{return{...prev, type: e.id}});
                }}
                options={types?.map(v=>{return {title: v.title_en, id: v.id}})}
                selected={documentInfo.type}/>
            || img_types.indexOf(form.input_type) > -1 &&
            <ListPicker options={{viewImage: true}} lang={"en"} items={media.filter(x => x.type === form.input_type)?.map(x => {return {...x, image: getImage(x.image, "media")}})} onChange={(e)=>setFormValues(prev=>{return{...prev, [`${form.id}`]: {...prev?.[form.id], text_hy: e}}})} value={formValues?.[form.id]?.text_hy}/>
            || video_types.indexOf(form.input_type) > -1 &&
            <ListPicker options={{viewImage: true}} lang={"en"} items={media.filter(x => x.type === form.input_type)?.map(x => {return {...x, image: "https://i.ytimg.com/vi_webp/"+ x.image +"/sddefault.webp"}})} onChange={(e)=>setFormValues(prev=>{return{...prev, [`${form.id}`]: {...prev?.[form.id], text_hy: e}}})} value={formValues?.[form.id]?.text_hy}/>}
    </Div>
})}    <Space line={true}/>
                            </div>
})}
                        </>

                    },
                ]}/>

            </Dialog>}
            {documentRemove && <Dialog
                buttons={{
                    right: <Div gap={12}>
                        <Button title={"Չեղարկել"} color={"transparent"} onClick={() => setDocumentRemove(false)}/>
                        <Button title={"Հեռացնել"} color={"red"} onClick={() => {
                            conn("forms/remove", {id: documentInfo.id}).then(() => window.location.reload())
                        }}/>
                    </Div>
                }}
                size={"xsm"} title={"Հեռացնել"} onClose={() => setDocumentRemove(false)}>
                <Text align={"center"}>Դուք ի՞րոք ցանկանում եք հեռացնել <Text
                    weight={"bold"}>{documentInfo.title_en}?</Text></Text>
            </Dialog>}
        </Layout>
    );
};

export default DocumentsForms;


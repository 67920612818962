import React, {useEffect, useMemo, useState} from 'react';
import Preloader from "../../components/Preloader/Preloader";
import Text from "../../components/Text/Text";
import Space from "../../components/Space/Space";
import Layout from "../../components/Layout/Layout";
import {getImage, sortBy} from "../../helpers";
import Uploader from "../../components/Uploader/Uploader";
import {conn, uploadFile} from "../../helpers/request/connect";
import Editor from "../../components/Editor/Editor";
import Button from "../../components/Button/Button";
import Dialog from "../../components/Dialog/Dialog";
import LangTabs from "../../components/LangTabs/LangTabs";
import Input from "../../components/Input/Input";
import Div from "../../components/Div/Div";
import ListItem from "../../components/ListItem/ListItem";
import {Select} from "../../components/Select/Select";

const DocumentsFormSections = () => {

    const [loading, setLoading] = useState(true);
    const [items, setItems] = useState([]);
    const [nDocument, setNDocument] = useState(false);
    const [documentRemove, setDocumentRemove] = useState(false);
    const [documentInfo, setDocumentInfo] = useState<any>({});


    useEffect(() => {
        conn("document-form-items/getSections").then((res: any) => {
            if (res.items) setItems(res.items);
            setLoading(false);
        });
    }, [])


    const saveDocument = (data) => {
        conn("document-form-items/saveSectionItem", data).then((res) => {
             window.location.reload();
        });
    }





    return (
        <Layout>
            <Preloader active={loading}/>
            <Text size={"md"} weight={"bold"}>Document form items</Text>
            <Space size={"sm"}/>
            <Button color={"green"} title={"Ավելացնել նորը"} stretch={"fixed"} onClick={() => setNDocument(true)}/>
            <Space/>
            <Div column>
                {items.map((val) => {
                    return <ListItem hover={true} key={val.id} title={val.sort + " " + val.title_hy} onClick={() => {
                        setDocumentInfo(val);
                        setNDocument(true);
                    }}/>
                })}
            </Div>


            {nDocument && <Dialog buttons={{
                right: <Div gap={12}>
                    <Button title={"Պահպանել"} color={"green"} stretch={"fixed"} onClick={() => {

                        if(!documentInfo.title_en?.length || !documentInfo.title_hy?.length) alert("Լրացրեք պարտադիր դաշտերը");
                        else {
                            setLoading(true);
                            saveDocument(documentInfo);
                            setDocumentInfo({});
                        }
                    }}/>
                    {documentInfo.id &&
                        <Button title={"Հեռացնել"} onClick={() => setDocumentRemove(true)} color={"red"}/>}
                </Div>
            }} size={"xsm"} title={"Document form section"} onClose={() => {
                setNDocument(false);
                setDocumentInfo({});
            }}>

                <Space size={"sm"}/>
                <LangTabs tabs={[

                    {
                        title: "Հայերեն",
                        content: <><Input placeholder={"Վերնագիր"} value={documentInfo.title_hy}
                                          onChange={(e) => setDocumentInfo(prev => {
                                              return {...prev, title_hy: e}
                                          })}/>
                        </>
                    },
                    {
                        title: "English",
                        content: <><Input placeholder={"Title"} value={documentInfo.title_en}
                                          onChange={(e) => setDocumentInfo(prev => {
                                              return {...prev, title_en: e}
                                          })}/>
                        </>
                    },
                ]}/>
                <Space size={"sm"}/>
                <Text size={"xmd"} weight={"bold"}>Order</Text>
                <Input placeholder={"Order"} value={documentInfo.sort ?? "0"} onChange={(e)=>setDocumentInfo(prev=>{return{...prev, sort: e}})}/>
            </Dialog>}
            {documentRemove && <Dialog
                buttons={{
                    right: <Div gap={12}>
                        <Button title={"Չեղարկել"} color={"transparent"} onClick={() => setDocumentRemove(false)}/>
                        <Button title={"Հեռացնել"} color={"red"} onClick={() => {
                            conn("document-form-items/removeSection", {id: documentInfo.id}).then(() => window.location.reload())
                        }}/>
                    </Div>
                }}
                size={"xsm"} title={"Հեռացնել"} onClose={() => setDocumentRemove(false)}>
                <Text align={"center"}>Դուք ի՞րոք ցանկանում եք հեռացնել <Text
                    weight={"bold"}>{documentInfo.title_hy}?</Text></Text>
            </Dialog>}
        </Layout>
    );
};

export default DocumentsFormSections;


import React, {useEffect, useMemo, useState} from 'react';
import Preloader from "../../components/Preloader/Preloader";
import Text from "../../components/Text/Text";
import Space from "../../components/Space/Space";
import Layout from "../../components/Layout/Layout";
import {getImage, sortBy} from "../../helpers";
import Uploader from "../../components/Uploader/Uploader";
import {conn, uploadFile} from "../../helpers/request/connect";
import Editor from "../../components/Editor/Editor";
import Button from "../../components/Button/Button";
import Dialog from "../../components/Dialog/Dialog";
import LangTabs from "../../components/LangTabs/LangTabs";
import Input from "../../components/Input/Input";
import Div from "../../components/Div/Div";
import ListItem from "../../components/ListItem/ListItem";
import {Select} from "../../components/Select/Select";
import {types} from "sass";
import Number = types.Number;

const Regions = () => {

    const [loading, setLoading] = useState(true);
    const [info, setInfo] = useState<any>({bold: false});
    const [items, setItems] = useState([]);
    const [editRegion, setEditRegion] = useState(false);


    useEffect(() => {
        conn("regions/get").then((res: any) => {
            if (res.items.length) setItems(sortBy(res.items, "sort"));
            setLoading(false);
        })

    }, [])

    const regions = useMemo(()=>{
        const countries = items?.filter(x => !x.parent_id || x.parent_id === "0");
        countries.forEach(ca=>{
           ca.child = items.filter(x => x.parent_id === ca.id);
        });
        return countries;
    }, [items])

    const [regionRemove, setRegionRemove] = useState(false);

    return (
        <Layout>
            <Preloader active={loading}/>
            <Text size={"md"} weight={"bold"}>Բնակավայրեր</Text>
            <Space size={"sm"}/>
            <Space size={"sm"}/>
            <Button color={"green"} title={"Ավելացնել նոր բնակավայր"} onClick={() => setEditRegion(true)}/>
            <Space line={true}/>
            <Div column>
                {regions.map(ca=>{
                    return <Div key={"region-" + ca.id} column><ListItem hovered hover  title={<Text weight={"bold"}>{ca.id + " - " + ca.title_hy}</Text>} onClick={()=>{
                        setInfo(ca);
                        setEditRegion(true);
                    }}/>
                        {ca.child?.length && <>
                            <Space/>
                            {ca.child.map((child)=>{
                                return <ListItem key={"region-" + child.id} hover onClick={()=>{
                                    setInfo(child);
                                    setEditRegion(true);
                                }} title={child.title_hy} />
                            })}
                        </> || null}
                        <Space size={"xsm"}/>
                        <Button size={"xsm"} title={"Ավելացնել նորը"} color={"green"} onClick={()=>{
                            setInfo({parent_id: ca.id});
                            setEditRegion(true);
                        }}/>
                        <Space line={true} key={"space-" + ca.id}/>
                   </Div>
                })}
            </Div>

            {regionRemove && <Dialog
                buttons={{
                    right: <Div gap={12}>
                        <Button title={"Չեղարկել"} color={"transparent"} onClick={() => setRegionRemove(false)}/>
                        <Button title={"Հեռացնել"} color={"red"} onClick={() => {
                            conn("regions/remove", {id: info.id}).then(() => window.location.reload())
                        }}/>
                    </Div>
                }}
                size={"xsm"} title={"Հեռացնել"} onClose={() => setRegionRemove(false)}>
                <Text align={"center"}>Դուք ի՞րոք ցանկանում եք հեռացնել <Text
                    weight={"bold"}>{info.title_en}?</Text></Text>
            </Dialog>}

            {editRegion && <Dialog buttons={{
                right: <Div gap={12}>
                    <Button title={"Պահպանել"} color={"green"} stretch={"fixed"} onClick={() => {

                        if(info.title_en?.length && info.title_hy?.length){
                            setLoading(true);
                            conn("regions/saveItem", info).then(()=>{
                                window.location.reload();
                            })
                        }else{
                            alert("Լրացրեք պարտադիր դաշտերը");
                        }
                    }}/>
                    {info.id &&
                        <Button title={"Հեռացնել"} onClick={() => setRegionRemove(true)} color={"red"}/>}
                </Div>
            }} size={"md"} title={"Բնակավայր"} onClose={() => {
                setEditRegion(false);
                setInfo({});
            }}>

                <Space size={"sm"}/>
                <Select selected={info.parent_id} options={[{title: "No parent", id: ""}, ...regions?.filter(x => x.id !== info.id)?.map(v=>{return {title: v.title_hy, id: v.id}})]} onChange={(e)=>setInfo(prev=>{return{...prev, parent_id: e.id}})} placeholder={"No parent"}/>
                <Space size={"sm"}/>
                {(info.parent_id && info.parent_id !== "0") && <Input stretch={"full"} label={"Coordinates"} placeholder={"39.825661, 46.758323"}
                        value={info.coordinates}
                        onChange={(e) => setInfo(prev => {
                            return {...prev, coordinates: e}
                        })}/> || null}
                <Space/>
                <LangTabs tabs={[
                    {
                        title: "Հայերեն",
                        content: <><Input label={"Անվանում"} stretch={"full"} placeholder={"Անվանում"} value={info.title_hy}
                                          onChange={(e) => setInfo(prev => {
                                              return {...prev, title_hy: e}
                                          })}/>
                            <Space size={"sm"}/>
                            <Editor key={"text-hy"} value={info.text_hy}
                                    onChange={(e) => setInfo(prev => {
                                        return {...prev, text_hy: e}
                                    })}/>
                        </>
                    },
                    {
                        title: "English",
                        content: <><Input label={"Title"} stretch={"full"} placeholder={"Title"} value={info.title_en}
                                          onChange={(e) => setInfo(prev => {
                                              return {...prev, title_en: e}
                                          })}/>
                            <Space size={"sm"}/>
                            <Editor key={"text-en"} value={info.text_en}
                                    onChange={(e) => setInfo(prev => {
                                        return {...prev, text_en: e}
                                    })}/>
                        </>
                    },
                ]}/>

            </Dialog>}

        </Layout>
    );
};

export default Regions;


import React, {useEffect, useMemo, useState} from 'react';
import Div from "../Div/Div";
import {Link} from "react-router-dom";
import Text from "../Text/Text";
import "./Header.scss";
import Space from "../Space/Space";
import Button from "../Button/Button";
import {RxExit} from "react-icons/rx";
import {cookies} from "../../helpers";
import {useParams} from "react-router";
import {conn} from "../../helpers/request/connect";
import {GiHamburgerMenu} from "react-icons/gi";

const Header = () => {
    const params = useParams();

    const [counts, setCounts] = useState({
        donate: 0,
        contacts: 0
    });

    const closeMenu = (e)=>{
      if(!e.target.closest(".menu-button")) setOpened(false);
    }

    useEffect(()=>{
        document.addEventListener("click", closeMenu);

        return ()=> document.removeEventListener("click", closeMenu);

    }, [])

    const [opened, setOpened] = useState(false);

    const menu = useMemo(()=>{
        return [
            {title: "Main", url: "/"},
            {title: "Գլխավոր", url: "/home"},
            {title: "Հուշարձաններ", url: "/monuments"},
            {title: "Վավերագրեր", url: "/monument-documents"},
            {title: "Ֆորմաներ", url: "/monument-form-options"},
            {title: "Ծրագրեր", url: "/programs"},
            {title: "Գրականություն", url: "/literature"},
            {title: "Գործընկերներ", url: "/partners"},
            {title: "Մեդիա", url: "/media"},
            {title: "Հետադարձ կապ ("+ counts.contacts +")", url: "/contacts"},
            {title: "Նվիրատվություններ ("+ counts.donate +")", url: "/donates"},
            {title: "Կարգավորումներ", url: "/settings"},
        ]
    }, [counts]);

    const [time, setTime] = useState<any>(null);

    const getCounts = ()=>{
        conn("contacts/count").then((res: any)=>{
            setCounts({
                donate: res.donates,
                contacts: res.contacts
            })
        });
    }

    useEffect(()=>{
        getCounts();
        setTime(setInterval(getCounts, 10000));

        return ()=>{
            clearInterval(time);
        }

    }, [])


    return (
        <Div column className={"header"} alignH={"center"}>

            <Div className={"header-in"}>
            <Space size={"sm"}/>
                <Button className={"menu-button"} iconLeft={<GiHamburgerMenu/>} onClick={()=>setOpened(!opened)}/>
            <Div className={"menu-content" + (opened? " opened": "")} gap={12} wrap={true} column={true}>
                {menu.map((v, i)=>{
                    return <div key={"menu-"+i}>
                        <Link to={v.url}><Text color={"/" + params.p1 === v.url || !params.p1 && v.url === "/"? "main": "light"} singleLine={true}>{v.title}</Text></Link>
                    </div>
                })}
                <Space size={"sm"} vertical={false}/>
                <Space vertical={false} size={"auto"}/>
            <Button onClick={()=>{
                cookies.set("ad_token", "");
                cookies.set("ad_id", "");
                window.location.reload();
            }} title={<Text color={"light"}>Ելք</Text>} color={"transparent"} iconRight={<RxExit/>}/>
            </Div>
            <Space size={"sm"}/>
            </Div>
        </Div>
    );
};

export default Header;

import * as React from "react";
import classNames from "classnames";
import "./Select.scss";
import {useEffect, useMemo, useRef, useState} from "react";
import {AiFillCaretDown} from "react-icons/ai";
import Text from "../Text/Text";
import Space from "../Space/Space";
import ContextMenu from "../ContextMenu/ContextMenu";
import Input from "../Input/Input";
import Div from "../Div/Div";
import {conditionSearch} from "../../helpers/search";

export interface ISelect {
    options: any,
    selected?: string | number;
    onChange?: (e: any)=>void,
    mini?: boolean,
    middle?: boolean,
    placeholder?: string,
    search?: boolean,
}

export const Select = (props:ISelect)=>{
    const {options, selected, onChange = ()=>{}, mini, middle, placeholder, search} = props;
    const [selectedItem, setSelectedItem] = useState<any>({});
    const [opened, setOpened] = useState(false);
    const classes = classNames("select", {
        empty: !selectedItem.id,
        opened: opened,
        mini: mini,
        middle: middle
    });

    const ref = useRef();
    const [context, setContext] = useState(null);
    const [searchVal, setSearchVal] = useState("");

    useEffect(()=>{
        setSelectedItem(options?.find((x: any)=> x.id === selected) ?? {title: placeholder ?? "Select"});
    }, [options, selected]);

    const optionsVal = useMemo(()=>{
        return searchVal?.length ? options.filter(x => conditionSearch(x.title, searchVal)) : options;
    }, [options, searchVal])

    const closeOnClickDocument = (e:any)=>{
        if(e.target.closest(".select") !== ref.current) setOpened(false);
    };

    useEffect(()=>{
        document.addEventListener("click", closeOnClickDocument);

        return ()=>{
            document.removeEventListener("click", closeOnClickDocument);
        }

    }, [ref])

    const itemChange = (x:any)=>{
        onChange(x);
        setSelectedItem(x);
        setOpened(false);
    }

    return (
        <div className={classes} ref={ref}>
            <div className={"select-title-content"} onClick={(e:any)=>setContext(e.target.closest(".select-title-content"))}>
                <div className={"select-title"}>{selectedItem.title}</div>
                <div className={"select-icon"}>
                    <AiFillCaretDown/>
            </div>
                {context && <ContextMenu
                    alignTo={context}
                    onClose={()=>setContext(null)}
                    noScrollTop={search? <Div onClick={(e)=>e.stopPropagation()} alignV={"center"}>
                        <Space vertical={false} size={"xsm"}/>
                        <Input
                            clearButton={true}
                            onClear={()=>setSearchVal("")}
                            autofocus={true}
                            placeholder={"Search"}
                            value={searchVal}
                            onChange={setSearchVal}
                    />
                        <Space vertical={false} size={"xsm"}/>
                    </Div>: undefined}
                    content={[<div className={"select-list"}>
                    {optionsVal?.length && optionsVal.map((val: any, i: number)=>{
                        return <div key={"select-" + i}  onClick={()=> {
                            itemChange(val);
                            setSearchVal("");
                        }} className={`select-list-item ${selectedItem.id === val.id? "selected": ""}`}>{val.title}</div>
                    }) || <div key={"select-empty"}><Space/><Text color={"grey"} align={"center"}>Empty</Text><Space/></div>}
                </div>]}
                />}
            </div></div>
    )
}
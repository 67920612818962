import React, {useEffect, useMemo, useRef, useState} from 'react';
import Preloader from "../../components/Preloader/Preloader";
import Text from "../../components/Text/Text";
import Space from "../../components/Space/Space";
import Layout from "../../components/Layout/Layout";
import {getImage, sortBy} from "../../helpers";
import Uploader from "../../components/Uploader/Uploader";
import {conn, uploadFile} from "../../helpers/request/connect";
import Editor from "../../components/Editor/Editor";
import Button from "../../components/Button/Button";
import Dialog from "../../components/Dialog/Dialog";
import LangTabs from "../../components/LangTabs/LangTabs";
import Input from "../../components/Input/Input";
import Div from "../../components/Div/Div";
import ListItem from "../../components/ListItem/ListItem";
import {Select} from "../../components/Select/Select";
import {MdAttachFile, MdDelete} from "react-icons/md";
import {FaFileAlt} from "react-icons/fa";
import {Link} from "react-router-dom";

const Contacts = () => {

    const [loading, setLoading] = useState(true);
    const [info, setInfo] = useState<any>({bold: false});
    const [items, setItems] = useState([]);
    const [regions, setRegions] = useState([]);
    const [nDocument, setNDocument] = useState(false);
    const [documentRemove, setDocumentRemove] = useState(false);
    const [documentInfo, setDocumentInfo] = useState<any>({})
    const [fileEdit, setFileEdit] = useState(false);
    const [fileEditInfo, setFileEditInfo] = useState<any>({});
    const [files, setFiles] = useState([]);
    const [fileRemove, setFileRemove] = useState(false);

    const fileRef = useRef();


    useEffect(() => {
        conn("contacts/getDonates").then((res: any) => {
            if (res.items) setItems(res.items);
            setLoading(false);
        });

    }, []);

    useEffect(() => {
        if(documentInfo.id){
            setLoading(true);
            conn("contacts/getFiles", {id: documentInfo.id}).then((res: any) => {
                if (res.items) setFiles(res.items);
                setLoading(false);
            });
        }
    }, [documentInfo.id]);


    return (
        <Layout>
            <Preloader active={loading}/>
            <Text size={"md"} weight={"bold"}>Նվիրատվություններ</Text>
            <Space/>
            <Div column gap={2}>
                {items.map((val) => {
                    return <ListItem
                        rightIcon={<Div alignH={"center"}><MdAttachFile/><Text>{val.count}</Text></Div>}
                        selected={!Number(val.is_read)}
                        hover={true} key={val.id}
                        title={val.name + " | " + val.message}
                        onClick={() => {
                        setDocumentInfo(val);
                        setNDocument(true);
                       conn("contacts/read", {type: "donate", id: val.id}).then();
                        setItems(prev=>{return prev.map(v=>{
                            return v.id === val.id? {...v, is_read: "1"} : v
                        })});
                    }}/>
                })}
            </Div>


            {nDocument && <Dialog buttons={{
                right: <Div gap={12}>

                        <Button title={"Փակել"} onClick={() => {
                            setDocumentInfo({});
                            setNDocument(false);
                        }} color={"transparent"}/>
                        <Button title={"Հեռացնել"} onClick={() => {
                            setDocumentRemove(documentInfo.id);
                        }} color={"red"}/>

                </Div>
            }} size={"md"} title={"Հաղորդագրություն"} onClose={() => {
                setNDocument(false);
                setDocumentInfo({});
            }}>

                <Space size={"sm"}/>
                <Div column>
                    <Text color={"grey"}>Անուն</Text>
                    <Text>{documentInfo.name}</Text>
                    <Space/>
                </Div>
                <Div column>
                    <Text color={"grey"}>Էլ.հասցե</Text>
                    <Text>{documentInfo.email}</Text>
                    <Space/>
                </Div>
                <Div column>
                    <Text color={"grey"}>Թեմա</Text>
                    <Text>{documentInfo.theme}</Text>
                    <Space/>
                </Div>
                <Div column>
                    <Text color={"grey"}>Հաղորդագրություն</Text>
                    <Text wrap={"pre"}>{documentInfo.message}</Text>
                    <Space/>
                </Div>
                <Div column>
                    <Text color={"grey"}>Ֆայլեր</Text>
                    <Div column gap={6}>
                        {files.length ? files.map(file=>{
                            return <Link to={"https://hishatakaran.org/uploads/donate-files/" + file.file} target={"_blank"}><Text>{file.filename}</Text></Link>
                        }) : <Text color={"grey"} align={"center"}>Ֆայլեր չկան</Text>}
                    </Div>

                </Div>


            </Dialog>}

            {documentRemove && <Dialog
                buttons={{
                    right: <Div gap={12}>
                        <Button title={"Չեղարկել"} color={"transparent"} onClick={() => setDocumentRemove(false)}/>
                        <Button title={"Հեռացնել"} color={"red"} onClick={() => {
                            conn("contacts/deleteDonate", {id: documentInfo.id}).then(() => window.location.reload())
                        }}/>
                    </Div>
                }}
                size={"xsm"} title={"Հեռացնել"} onClose={() => setDocumentRemove(false)}>
                <Text align={"center"}>Դուք ի՞րոք ցանկանում եք հեռացնել <Text
                    weight={"bold"}>{documentInfo.title_hy}?</Text></Text>
            </Dialog>}

        </Layout>
    );
};

export default Contacts;


import React, {useEffect, useState} from 'react';
import Text from "../../../components/Text/Text";
import Layout from "../../../components/Layout/Layout";
import Space from "../../../components/Space/Space";
import Uploader from "../../../components/Uploader/Uploader";
import Input from "../../../components/Input/Input";
import LangTabs from "../../../components/LangTabs/LangTabs";
import Button from "../../../components/Button/Button";
import {conn, uploadFile} from "../../../helpers/request/connect";
import {getImage} from "../../../helpers";
import Preloader from "../../../components/Preloader/Preloader";
import Editor from "../../../components/Editor/Editor";

const HomePage = () => {

    const [info, setInfo] = useState<any>({
        about: {
            en: "",
            hy: "",
        },
        donation: {
            en: "",
            hy: "",
        },
        image: ""
    });

    const [err, setErr] = useState(false);
    const [loading, setLoading] = useState(true);
    const [coverChanged, setCoverChanged] = useState(false);

    const save = (v)=>{
        conn("home/save", v).then(res=>{
            window.location.reload();
        });
    }

    useEffect(()=>{
        conn("home/get").then((res:any)=>{
           if(res.response){
               setInfo({
                   about: {
                       en: res.response.text_en,
                       hy: res.response.text_hy,
                   },
                   donation: {
                       en: res.response.title_en,
                       hy: res.response.title_hy,
                   },
                   image: res.response.image
               })
           }
            setLoading(false);
        })

    }, [])

    return (
        <Layout>
            <Preloader active={loading}/>
        <Text size={"md"} weight={"bold"}>Գլխավոր էջ</Text>
        <Space size={"sm"}/>
        <Text size={"xmd"} color={"grey-middle"}>Նկար</Text>
        <Uploader value={info.image?(typeof info.image === "string"? getImage(info.image, "cover"): URL.createObjectURL(info.image)): undefined} disabled={loading} type={"image"} size={"l"} onChange={(e)=> {
            setInfo(prev => {
                return {...prev, image: e}
            });
        }}/>
        <Space size={"md"}/>
         <LangTabs
         tabs={[

             {title: "Հայերեն", content: <>
                     <Space size={"sm"}/>
                     <Text size={"xmd"} color={"grey-middle"}>Մեր մասին</Text>
                     <Editor key={"text-hy"} value={info.about.hy}
                             onChange={(e) => setInfo(prev=>{
                                 return {...prev, about: {...prev.about, hy: e}}
                             })}/>

                     <Space size={"sm"}/>
                     <Text size={"xmd"} color={"grey-middle"}>Նվիրատվություն</Text>

                     <Editor key={"donation-hy"} onChange={(e)=>setInfo(prev=>{
                     return {...prev, donation: {...prev.donation, hy: e}}
                 })} value={info.donation.hy}/>
                 </>},
             {title: "English", content: <>
                     <Space size={"sm"}/>
                     <Text size={"xmd"} color={"grey-middle"}>About us</Text>
                     <Editor key={"text-en"} value={info.about.en} onChange={(e)=>setInfo(prev=>{
                         return {...prev, about: {...prev.about, en: e}}
                     })}/>
                     <Space size={"sm"}/>
                     <Text size={"xmd"} color={"grey-middle"}>Donation</Text>
                     <Editor key={"donation-en"} value={info.donation.en} onChange={(e)=>setInfo(prev=>{
                         return {...prev, donation: {...prev.donation, en: e}}
                     })}/></>},
         ]}
         />
        <Space size={"md"}/>
            {err && <>
            <Text color={"red"}>Լրացրեք պարտադիր դաշտերը</Text>
                <Space size={"xsm"}/>
            </>}
            <Button loading={loading} title={"Պահպանել"} color={"green"} stretch={"fixed"} onClick={()=>{
                if(!info.about.hy.length
                    || !info.about.en.length
                    || !info.donation.en.length
                    || !info.donation.hy.length){
                    setErr(true);
                }else{
                    setErr(false);
                    setLoading(true);
                    if(info.image && typeof info.image !== "string"){
                        uploadFile(info.image, "cover").then((res:any)=>{
                           if(res.response){
                                save({...info, image: res.response});
                           }
                        });
                    }else{ save(info); }
                }
            }}/>
        </Layout>
    );
};

export default HomePage;

import React from 'react';
import Div from "../../components/Div/Div";
import {useParams} from "react-router";
import HomePage from "../EditPages/HomePage/HomePage";
import DocumentsEdit from "../EditPages/DocumentsEdit";
import Regions from "../EditPages/Regions";
import Programs from "../EditPages/Programs";
import TypesPage from "../EditPages/TypesPage";
import DocumentForm from "../EditPages/DocumentForm";
import DocumentsFormItems from "../EditPages/DocumentsFormItems";
import DocumentsFormOptions from "../EditPages/DocumentsFormOptions";
import DocumentsFormSections from "../EditPages/DocumentsFormSections";
import MediaPage from "../EditPages/MediaPage";
import MediaImages from "../EditPages/MediaImages";
import SettingsPage from "../EditPages/SettingsPage";
import Literature from "../EditPages/Literature";
import Contacts from "../EditPages/Contacts";
import Donates from "../EditPages/Donates";
import StatPage from "../EditPages/StatPage";
import Partners from "../EditPages/Partners";

const MainPage = () => {
    const params = useParams();
    return (
        <Div column>
            {params.p1 === "home" && <HomePage/>
            || params.p1 === "monuments" && <DocumentsEdit/>
            || params.p1 === "monument-documents" && <DocumentForm/>
            || params.p1 === "settings" && <SettingsPage/>
            || params.p1 === "literature" && <Literature/>
            || params.p1 === "contacts" && <Contacts/>
            || params.p1 === "donates" && <Donates/>
            || params.p1 === "partners" && <Partners/>
            || (params.p1 === "monument-form-options" && params.p2 === "monument-form-items") && <DocumentsFormItems/>
            || (params.p1 === "monument-form-options" && params.p2 === "monument-form-sections") && <DocumentsFormSections/>
            || (params.p1 === "monument-form-options" && params.p2 === "regions") && <Regions/>
            || (params.p1 === "monument-form-options" && params.p2 === "types") && <TypesPage/>
            || (params.p1 === "monument-form-options") && <DocumentsFormOptions/>
            || (params.p1 === "monument-form-options") && <TypesPage/>
            || params.p1 === "programs" && <Programs/>
            || (params.p1 === "media" && params.p2) && <MediaImages/>
            || params.p1 === "media" && <MediaPage/>
            || <StatPage/>
            }
        </Div>
    );
};

export default MainPage;

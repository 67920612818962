import React, {useEffect, useMemo, useState} from 'react';
import Preloader from "../../components/Preloader/Preloader";
import Text from "../../components/Text/Text";
import Space from "../../components/Space/Space";
import Layout from "../../components/Layout/Layout";
import {getImage, sortBy} from "../../helpers";
import Uploader from "../../components/Uploader/Uploader";
import {conn, uploadFile} from "../../helpers/request/connect";
import Editor from "../../components/Editor/Editor";
import Button from "../../components/Button/Button";
import Dialog from "../../components/Dialog/Dialog";
import LangTabs from "../../components/LangTabs/LangTabs";
import Input from "../../components/Input/Input";
import Div from "../../components/Div/Div";
import ListItem from "../../components/ListItem/ListItem";
import {Select} from "../../components/Select/Select";

const Programs = () => {

    const [loading, setLoading] = useState(true);
    const [info, setInfo] = useState<any>({bold: false});
    const [items, setItems] = useState([]);
    const [regions, setRegions] = useState([]);
    const [nDocument, setNDocument] = useState(false);
    const [documentRemove, setDocumentRemove] = useState(false);
    const [documentInfo, setDocumentInfo] = useState<any>({})
    useEffect(() => {
        conn("programs/get").then((res: any) => {
            if (res.items) setItems(res.items);
            if (res.response) {
                setInfo(res.response);
            }
            setLoading(false);
        });

    }, [])


    const saveDocument = (data) => {
        conn("programs/saveItem", data).then((res) => {
            window.location.reload();
        });
    }

    const saveAbout = (data)=>{
        conn("programs/save", data).then(res=>{
            window.location.reload();
        });
    }




    return (
        <Layout>
            <Preloader active={loading}/>
            <Text size={"md"} weight={"bold"}>Ծրագրեր</Text>
            <Space size={"sm"}/>
            <Text size={"xmd"} color={"grey-middle"}>Նկար</Text>
            <Uploader
                value={info.image ? (typeof info.image === "string" ? getImage(info.image, "cover") : URL.createObjectURL(info.image)) : undefined}
                disabled={loading} type={"image"} size={"l"} onChange={(e) => {
                setInfo(prev=>{return{...prev, image: e}});
            }}/>
            <Space/>
            <LangTabs tabs={[

                {
                    title: "Հայերեն",
                    content: <>
                        <Space size={"sm"}/>
                        <Text size={"xsm"} color={"grey"}>Մեր մասին</Text>
                        <Space size={"xsm"}/>
                        <Editor key={"text-hy"} value={info.text_hy}
                                onChange={(e) => setInfo(prev => {
                                    return {...prev, text_hy: e}
                                })}/>
                    </>
                },
                {
                    title: "English",
                    content: <>
                        <Space size={"sm"}/>
                        <Text size={"xsm"} color={"grey"}>About us</Text>
                        <Space size={"xsm"}/>
                        <Editor key={"text-en"} value={info.text_en}
                                onChange={(e) => setInfo(prev => {
                                    return {...prev, text_en: e}
                                })}/>
                    </>
                },
            ]}/>

            <Space/>
            <Div alignV={"end"}><Button onClick={()=>{
                setLoading(true);
                if (info.image && typeof info.image !== "string") {
                    uploadFile(info.image, "cover").then((res: any) => {
                        saveAbout({...info, image: res.response});
                    });
                }else{
                    saveAbout(info);
                }
            }} title={"Պահպանել"} color={"green"}/></Div>

            <Space line={true}/>

            <Space size={"sm"}/>
            <Button color={"green"} title={"Ավելացնել նոր ծրագիր"} onClick={() => setNDocument(true)}/>
            <Space/>
            <Div column>
                {items.map((val) => {
                    return <ListItem hover={true} key={val.id} title={val.sort +" "+ val.title_hy} onClick={() => {
                        setDocumentInfo(val);
                        setNDocument(true);
                    }}/>
                })}
            </Div>


            {nDocument && <Dialog buttons={{
                right: <Div gap={12}>
                    <Button title={"Պահպանել"} color={"green"} stretch={"fixed"} onClick={() => {

                        if(!documentInfo.image) alert("Select image");
                        else if(!documentInfo.title_en?.length || !documentInfo.title_hy?.length) alert("Լրացրեք պարտադիր դաշտերը");
                        else {
                            setLoading(true);
                            if (documentInfo.image && typeof documentInfo.image !== "string") {
                                uploadFile(documentInfo.image, "cover", true).then((res: any) => {
                                    saveDocument({...documentInfo, image: res.response});
                                })
                            } else {
                                saveDocument(documentInfo)
                            }
                            setDocumentInfo({});
                        }
                    }}/>
                    {documentInfo.id &&
                        <Button title={"Հեռացնել"} onClick={() => setDocumentRemove(true)} color={"red"}/>}
                </Div>
            }} size={"md"} title={"Ծրագիր"} onClose={() => {
                setNDocument(false);
                setDocumentInfo({});
            }}>
                <Uploader size={"l"}
                          value={documentInfo.image ? (typeof documentInfo.image === "string" ? getImage(documentInfo.image, "cover") : URL.createObjectURL(documentInfo.image)) : undefined}
                          onChange={(e) => setDocumentInfo(prev => {
                              return {...prev, image: e}
                          })}/>

                <Space size={"sm"}/>
                <LangTabs tabs={[

                    {
                        title: "Հայերեն",
                        content: <>
                            <Text size={"xmd"} weight={"bold"}>Նկարի տակագիր</Text>
                            <Space size={"xsm"}/>
                            <Input stretch={"full"} placeholder={"Տակագիր"} value={documentInfo.caption_hy}
                                   onChange={(e) => setDocumentInfo(prev => {
                                       return {...prev, caption_hy: e}
                                   })}/>
                            <Space/>
                            <Input label={"Վերնագիր"} placeholder={"Վերնագիր"} value={documentInfo.title_hy}
                                          onChange={(e) => setDocumentInfo(prev => {
                                              return {...prev, title_hy: e}
                                          })}/>
                            <Space size={"sm"}/>
                            <Text size={"xsm"} color={"grey"}>Նկարագրութնուն</Text>
                            <Space size={"xsm"}/>
                            <Editor key={"text-hy"} value={documentInfo.text_hy}
                                    onChange={(e) => setDocumentInfo(prev => {
                                        return {...prev, text_hy: e}
                                    })}/>
                        </>
                    },
                    {
                        title: "English",
                        content: <>
                            <Text size={"xmd"} weight={"bold"}>Image caption</Text>
                            <Space size={"xsm"}/>
                            <Input stretch={"full"} placeholder={"Caption"} value={documentInfo.caption_en}
                                   onChange={(e) => setDocumentInfo(prev => {
                                       return {...prev, caption_en: e}
                                   })}/>
                            <Space/>
                            <Input label={"Title"} placeholder={"Title"} value={documentInfo.title_en}
                                          onChange={(e) => setDocumentInfo(prev => {
                                              return {...prev, title_en: e}
                                          })}/>
                            <Space size={"sm"}/>
                            <Text size={"xsm"} color={"grey"}>Description</Text>
                            <Space size={"xsm"}/>
                            <Editor key={"text-en"} value={documentInfo.text_en}
                                    onChange={(e) => setDocumentInfo(prev => {
                                        return {...prev, text_en: e}
                                    })}/>
                        </>
                    },
                ]}/>

            </Dialog>}
            {documentRemove && <Dialog
                buttons={{
                    right: <Div gap={12}>
                        <Button title={"Չեղարկել"} color={"transparent"} onClick={() => setDocumentRemove(false)}/>
                        <Button title={"Հեռացնել"} color={"red"} onClick={() => {
                            conn("programs/remove", {id: documentInfo.id}).then(() => window.location.reload())
                        }}/>
                    </Div>
                }}
                size={"xsm"} title={"Հեռացնել"} onClose={() => setDocumentRemove(false)}>
                <Text align={"center"}>Դուք ի՞րոք ցանկանում եք հեռացնել <Text
                    weight={"bold"}>{documentInfo.title_hy}?</Text></Text>
            </Dialog>}
        </Layout>
    );
};

export default Programs;


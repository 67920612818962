import React, {useEffect, useMemo, useState} from 'react';
import Preloader from "../../components/Preloader/Preloader";
import Text from "../../components/Text/Text";
import Space from "../../components/Space/Space";
import Layout from "../../components/Layout/Layout";
import {getImage, sortBy} from "../../helpers";
import Uploader from "../../components/Uploader/Uploader";
import {conn, uploadFile} from "../../helpers/request/connect";
import Editor from "../../components/Editor/Editor";
import Button from "../../components/Button/Button";
import Dialog from "../../components/Dialog/Dialog";
import LangTabs from "../../components/LangTabs/LangTabs";
import Input from "../../components/Input/Input";
import Div from "../../components/Div/Div";
import ListItem from "../../components/ListItem/ListItem";
import {Select} from "../../components/Select/Select";
import {useParams} from "react-router";
import {Simulate} from "react-dom/test-utils";
import error = Simulate.error;

const Partners = () => {

    const [loading, setLoading] = useState(true);
    const [info, setInfo] = useState<any>({bold: false});
    const [items, setItems] = useState([]);
    const [regions, setRegions] = useState([]);
    const [nDocument, setNDocument] = useState(false);
    const [documentRemove, setDocumentRemove] = useState(false);
    const [documentInfo, setDocumentInfo] = useState<any>({});
    const [types, setTypes] = useState([]);

    useEffect(() => {
        conn("partners/get").then((res: any) => {
            if (res.items) setItems(res.items);
            setLoading(false);
        });

    }, [])


    const saveDocument = (data) => {
        conn("partners/savePartner", data).then((res) => {
            window.location.reload();
        });
    }

    const params = useParams();




    return (
        <Layout>
            <Preloader active={loading}/>
            <Text size={"md"} weight={"bold"}>Partners</Text>

            <Space size={"sm"}/>
            <Button color={"green"} title={"Ավելացնել նորը"} stretch={"fixed"} onClick={() => setNDocument(true)}/>
            <Space/>
            <Div column>
                {items.map((val) => {
                    return <ListItem size={"lg"} leftIcon={<img src={getImage(val.image, "partners")}/>} hover={true} key={val.id} title={val.title_hy} onClick={() => {
                        setDocumentInfo(val);
                        setNDocument(true);
                    }}/>
                })}
            </Div>


            {nDocument && <Dialog buttons={{
                right: <Div gap={12}>
                    <Button title={"Պահպանել"} color={"green"} stretch={"fixed"} onClick={() => {
                        if(!documentInfo.image) alert("Select image");
                        else {
                            setLoading(true);
                            if (documentInfo.image && typeof documentInfo.image !== "string") {
                                uploadFile(documentInfo.image, "partners").then((res: any) => {
                                  if(res.error){
                                      alert(`Error: ${res.error === 2? "File format error": "File size error"}`)
                                      setLoading(false);
                                  }else{
                                      saveDocument({...documentInfo, image: res.response});
                                  }
                                })
                            } else {
                                saveDocument({...documentInfo});
                            }
                            setDocumentInfo({});
                        }
                    }}/>
                    {documentInfo.id &&
                        <Button title={"Հեռացնել"} onClick={() => setDocumentRemove(true)} color={"red"}/>}
                </Div>
            }} title={"Partner"} onClose={() => {
                setNDocument(false);
                setDocumentInfo({});
            }}>
                <Uploader
                           value={documentInfo.image ? (typeof documentInfo.image === "string" ? getImage(documentInfo.image, "partners") : URL.createObjectURL(documentInfo.image)) : undefined}
                           onChange={(e) => setDocumentInfo(prev => {
                               return {...prev, image: e}
                           })}/>

                <Space size={"sm"}/>
                <Input type={"number"} stretch={"full"} label={"Sort"} placeholder={"Sort"} value={documentInfo.sort ?? "0"}
                       onChange={(e) => setDocumentInfo(prev => {
                           return {...prev, sort: e}
                       })}/>
                <Space/>
                <LangTabs tabs={[

                    {
                        title: "Հայերեն",
                        content: <>
                            <Input stretch={"full"} label={"Վերնագիր"} placeholder={"Վերնագիր"} value={documentInfo.title_hy}
                                          onChange={(e) => setDocumentInfo(prev => {
                                              return {...prev, title_hy: e}
                                          })}/>
                        </>
                    },
                    {
                        title: "English",
                        content: <>
                            <Input stretch={"full"} label={"Title"} placeholder={"Title"} value={documentInfo.title_en}
                                          onChange={(e) => setDocumentInfo(prev => {
                                              return {...prev, title_en: e}
                                          })}/>
                        </>
                    }
                ]}/>

            </Dialog>}
            {documentRemove && <Dialog
                buttons={{
                    right: <Div gap={12}>
                        <Button title={"Չեղարկել"} color={"transparent"} onClick={() => setDocumentRemove(false)}/>
                        <Button title={"Հեռացնել"} color={"red"} onClick={() => {
                            conn("partners/removePartner", {id: documentInfo.id}).then(() => window.location.reload())
                        }}/>
                    </Div>
                }}
                size={"xsm"} title={"Հեռացնել"} onClose={() => setDocumentRemove(false)}>
                <Text align={"center"}>Դուք ի՞րոք ցանկանում եք հեռացնել <Text
                    weight={"bold"}>{documentInfo.title_en}?</Text></Text>
            </Dialog>}
        </Layout>
    );
};

export default Partners;


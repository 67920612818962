import React, {useEffect, useState} from 'react';
import Text from "../../components/Text/Text";
import Space from "../../components/Space/Space";
import Layout from "../../components/Layout/Layout";
import Div from "../../components/Div/Div";
import ListItem from "../../components/ListItem/ListItem";
import {Link} from "react-router-dom";
import Preloader from "../../components/Preloader/Preloader";
import Uploader from "../../components/Uploader/Uploader";
import {getImage} from "../../helpers";
import {conn, uploadFile} from "../../helpers/request/connect";

const MediaPage = () => {

    const [info, setInfo] = useState<any>({});
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        conn("media/getImage").then((res: any) => {
            if (res.response) {
                setInfo({image: res.response.image});
            }
            setLoading(false);
        });
    }, []);

    return (
        <Layout>
            <Preloader active={loading}/>
            <Text size={"md"} weight={"bold"}>Media</Text>
            <Space size={"sm"}/>
            <Text size={"xmd"} color={"grey-middle"}>Նկար</Text>
            <Uploader
                value={info.image ? (typeof info.image === "string" ? getImage(info.image, "cover") : URL.createObjectURL(info.image)) : undefined}
                disabled={loading} type={"image"} size={"l"} onChange={(e) => {
                if (e) {
                    setLoading(true);
                    uploadFile(e, "cover").then((res: any) => {
                        conn("media/save", {image: res.response}).then(() => window.location.reload());
                    });
                }
            }}/>
            <Space size={"sm"}/>
            <Space/>
            <Div column>
                <Text weight={"bold"} size={"xmd"}>Նկարներ</Text>
                <Space size={"xsm"}/>
                <Link to={"/media/plans"} key={"doc-opt-images"}><ListItem hover={true} title={"Հատակագծեր"}/></Link>
                <Link to={"/media/reorganizations"} key={"doc-opt-reorganizations"}><ListItem hover={true} title={"Վերակազմություններ"}/></Link>
                <Link to={"/media/photos"} key={"doc-opt-photos"}><ListItem hover={true} title={"Լուսանկարներ"}/></Link>
                <Link to={"/media/sketches"} key={"doc-opt-sketches"}><ListItem hover={true} title={"Գրչանկարներ"}/></Link>
                <Link to={"/media/metrics"} key={"doc-opt-metrics"}><ListItem hover={true} title={"Չափագրություններ"}/></Link>
                <Space/>
                <Text weight={"bold"} size={"xmd"}>Տեսահոլովակներ</Text>
                <Space size={"xsm"}/>
                <Link to={"/media/films"} key={"doc-opt-films"}><ListItem hover={true} title={"Ֆիլմեր"}/></Link>
                <Link to={"/media/filming"} key={"doc-opt-filming"}><ListItem hover={true} title={"Նկարահանումներ"}/></Link>
                <Link to={"/media/programs"} key={"doc-opt-programs"}><ListItem hover={true} title={"Հաղորդումներ"}/></Link>
            </Div>
        </Layout>
    );
};

export default MediaPage;

